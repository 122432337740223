import React from 'react'
import { IconNames } from 'rsuite/lib/Icon/Icon'

import Users, { USERS_ROUTE } from 'components/users'
//import Welcome, { WELCOME_ROUTE } from 'components/welcome'

interface ContainerItem {
  component: React.ComponentType<any>,
  icon: IconNames,
  key: string,
  path: string,
  text: string,
}

const DEFAULT_ROUTE = USERS_ROUTE

const containerItems: ContainerItem[] = [/*{
  component: Welcome,
  icon: 'dashboard',
  key: '1',
  path: WELCOME_ROUTE,
  text: 'Dashboard',
}, */{
  component: Users,
  icon: 'group',
  key: '2',
  path: USERS_ROUTE,
  text: 'Users',
}]

export { DEFAULT_ROUTE }

export default containerItems
