import React, { Fragment } from 'react'
import { Col, Panel, PanelGroup, Placeholder, Row } from 'rsuite'
import isString from 'lodash/isString'
import trim from 'lodash/trim'

import DataRow from 'components/DataRow'
import { formatDate } from 'util/utils'

import AccountStatus from './AccountStatus'
import InfoBalance from './Balance'
import CreditUser from './Credit'
import RewardsProgram from './RewardsProgram'
import AccountSessions from './AccountSessions'
import VerificationStatus from './VerificationStatus'

import { User, UserWithUpdate } from '../types'

interface BasicInfoProps {
  loading: boolean
  setUsersInfo: (item: User[]) => void,
  user: User
}

const BasicDetails = (user: UserWithUpdate) => (
  <Fragment>
    {<DataRow content="Account ID" data={user.id} />}
    {user.email && (<DataRow content="Email" data={isString(user.email) ? user.email : user.email.display} />)}
    {user.phone && (<DataRow content="Phone number" data={
      `${user.phone.phoneNumber}${user.phone.countryCode ? ` (${user.phone.countryCode})` : ''}`
    } />)}
    {user?.nickname?.display && (<DataRow content="Nickname" data={user.nickname?.display} />)}
    {<DataRow content="Created at" data={formatDate(user._insertTs)} />}
    {<DataRow content="Updated at" data={formatDate(user._updateTs)} />}
    {<DataRow content="Role" data={user.role} />}
    {<DataRow content="Verification Status" data={<VerificationStatus {...user} />} />}
    {<DataRow content="Account Status" data={<AccountStatus {...user} />} />}
    <InfoBalance accountId={user.id} />
  </Fragment>
)

const UserHeader = (user: User) => (
  <Fragment>
    <div style={{ float: 'right' }}>
      <CreditUser {...user} />
    </div>
    <h3>{user.name ? trim(`${trim(user.name.first)} ${trim(user.name.last)}`) : '-'}</h3>
  </Fragment>
)

export default ({ loading, setUsersInfo, user }: BasicInfoProps) => (
  <PanelGroup
    bordered
    className="container-users-basicInfo"
  >
    <Panel header={loading ? <Placeholder.Paragraph rows={1}/> : (<UserHeader {...user} />)}>
      {loading && (<Placeholder.Paragraph
        active
        rows={20}
      />)}
      {!loading && user && (<Row>
        <Col key="basic" md={12}>
          <BasicDetails {...user} setUsersInfo={setUsersInfo} />
        </Col>
        <Col key="rewards" md={12}>
          <RewardsProgram {...user} setUsersInfo={setUsersInfo} />
          <AccountSessions accountId={user.id} />
        </Col>
      </Row>)}
    </Panel>
  </PanelGroup>
)
