import React, { useState, Fragment } from 'react'
import { Alert, Button, Icon, Modal, Tag } from 'rsuite'
import omit from 'lodash/omit'

import { UserWithUpdate } from '../types'
import api from 'util/api'

const NOT_VERIFIED_TEXT = 'Not Verified'
const VERIFIED_TEXT = 'Verified'

export default (userWithUpdate: UserWithUpdate) => {
  const [status, setStatus] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  const verified = userWithUpdate?.flags?.verified
  const verifiedText = verified ? VERIFIED_TEXT : NOT_VERIFIED_TEXT

  const clearFields = () => {
    setStatus('')
  }

  const updateVerificationStatus = async () => {
    setUpdateLoading(true)

    try {
      if (status !== userWithUpdate.status) {
        await api.patch(`/dashboard/accounts/${userWithUpdate.id}/flags`, {
          verified: !verified
        })

        const newUserInfo = omit(userWithUpdate, 'setUsersInfo')
        newUserInfo.flags.verified = !verified
        userWithUpdate.setUsersInfo([newUserInfo])

        setShowModal(false)
        Alert.success('User Verification Status updated successfully', 4000)
      }
    } catch (ex) {
      Alert.error('An error occured when updating Verification Status, please try again later', 4000)
    } finally {
      setUpdateLoading(false)
    }
  }

  return (
    <Fragment>
      <Tag
        color={verifiedText === VERIFIED_TEXT ? 'green' : 'red'}
        style={{ padding: '0 8px' }}
      >{verifiedText}</Tag>
      <span
        className="element-interaction"
        onClick={() => setShowModal(!showModal)}
      >
        <Icon icon="edit2" style={{ marginLeft: 5 }} />
      </span>
      <Modal
        onExited={() => clearFields()}
        show={showModal}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Change Verification Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Account is currently <Tag
            color={verifiedText === VERIFIED_TEXT ? 'green' : 'red'}
            style={{ fontWeight: 'bold' }}
          >{verifiedText}</Tag>.
          Do you want to change it to <Tag
            color={verifiedText === VERIFIED_TEXT ? 'red' : 'green'}
            style={{ fontWeight: 'bold', marginLeft: 0 }}
          >{verifiedText === VERIFIED_TEXT ? NOT_VERIFIED_TEXT : VERIFIED_TEXT}</Tag>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            loading={updateLoading}
            onClick={() => updateVerificationStatus()}
          >Update</Button>
          <Button
            appearance="default"
            onClick={() => setShowModal(false)}
          >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
