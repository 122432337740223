import React, { useState } from 'react'
import { Button, ButtonGroup, Icon, Panel, PanelGroup } from 'rsuite'
import { IconNames } from 'rsuite/lib/Icon/Icon'

import HistoryChanges from './Changes'
import HistoryPayments from './Payments'
import HistoryShopping from './Shopping'
import HistoryTasks from './Tasks'
import HistoryTransactions from './Transactions'

import { User } from '../types'

interface HistoryProps {
  loading: boolean
  user: User
}

interface HistoryTab {
  component: React.ComponentType<any>,
  key: string
  icon: IconNames
  label: string
}

const historyTabs: HistoryTab [] = [{
  component: HistoryShopping,
  key: 'shopping',
  icon: 'shopping-cart',
  label: 'Shopping',
}, {
  component: HistoryTransactions,
  key: 'transactions',
  icon: 'exchange',
  label: 'Transactions',
}, {
  component: HistoryPayments,
  key: 'payments',
  icon: 'credit-card-alt',
  label: 'Payments',
}, {
  component: HistoryChanges,
  key: 'changes',
  icon: 'user-info',
  label: 'Changes',
}, {
  component: HistoryTasks,
  key: 'tasks',
  icon: 'task',
  label: 'Tasks',
}]

const DEFAULT_TAB = historyTabs[0].key

export default ({ loading, user }: HistoryProps) => {
  const accountId = user?.id

  const [active, setActive] = useState(DEFAULT_TAB)

  const activeComponent = historyTabs.find((historyTab) => historyTab.key === active)?.component
  let renderContent
  if (activeComponent) {
    renderContent = React.createElement(activeComponent, {...user})
  }

  return (
    <PanelGroup
      accordion
      bordered
      className="container-users-history"
    >
      <Panel
        header="Acount History"
        defaultExpanded
      >
        {!loading && accountId && (<ButtonGroup justified>
          {historyTabs.map((historyTab) => (<Button
            active={active === historyTab.key}
            appearance={active === historyTab.key ? 'primary' : 'default'}
            key={historyTab.key}
            onClick={() => setActive(historyTab.key)}
            size="lg"
          >
            <Icon
              icon={historyTab.icon}
              size="lg"
              style={{ marginRight: 10 }}
            />
            <strong>{historyTab.label}</strong>
          </Button>))}
        </ButtonGroup>)}
        {!loading && accountId && (renderContent)}
      </Panel>
    </PanelGroup>
  )
}
