import React, { useState, Fragment } from 'react'
import { Alert, Button, IconButton, Icon, Modal } from 'rsuite'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'
import trim from 'lodash/trim'

import DataHeader from 'components/DataHeader'
import DataRow from 'components/DataRow'
import api from 'util/api'
import { UserOAuthLink, UserWithUpdate } from '../types'

interface SocialOAuthProps {
  loading: boolean
  oauth: UserOAuthLink
  setModalState: (event: React.SyntheticEvent) => void
}

enum SocialOAuths {
  APPLE = 'apple',
  EMPTY = '',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}

const AppleOAauth = ({ loading, setModalState, oauth }: SocialOAuthProps) => (
  <div style={{ marginTop: 20 }}>
    <DataHeader component={<span key="apple-header">
      Apple
      <IconButton
        appearance="subtle"
        disabled={loading}
        icon={<Icon icon="unlink" />}
        loading={loading}
        onClick={setModalState}
        style={{ marginLeft: 5 }}
      />
    </span>} icon="apple" />
    <DataRow
      content="User ID"
      data={oauth.userId}
    />
    <DataRow
      content="Name"
      data={trim(`${trim(oauth?.data?.givenName)} ${trim(oauth?.data?.familyName)}`)}
    />
    <DataRow
      content="Email"
      data={oauth?.data?.email}
    />
    <DataRow
      content="Device IDs"
      data={isString(oauth.deviceIds) ? oauth.deviceIds :
        (isArray(oauth.deviceIds) ? oauth.deviceIds.map((deviceId, i) => (<div key={i}>{deviceId}</div>)) : '-')
      }
    />
  </div>
)

const FacebookOAauth = ({ loading, setModalState, oauth }: SocialOAuthProps) => (
  <div style={{ marginTop: 20 }}>
    <DataHeader component={<span key="facebook-header">
      Facebook
      <IconButton
        appearance="subtle"
        disabled={loading}
        icon={<Icon icon="unlink" />}
        loading={loading}
        onClick={setModalState}
        style={{ marginLeft: 5 }}
      />
    </span>} icon="facebook-square" />
    <DataRow
      content="User ID"
      data={oauth.userId}
    />
    <DataRow
      content="Name"
      data={oauth?.data?.name}
    />
    <DataRow
      content="Email"
      data={oauth?.data?.email}
    />
    <DataRow
      content="Device IDs"
      data={isString(oauth.deviceIds) ? oauth.deviceIds :
        (isArray(oauth.deviceIds) ? oauth.deviceIds.map((deviceId, i) => (<div key={i}>{deviceId}</div>)) : '-')
      }
    />
  </div>
)

const GoogleOAauth = ({ loading, setModalState, oauth }: SocialOAuthProps) => (
  <div style={{ marginTop: 20 }}>
    <DataHeader component={<span key="google-header">
      Google
      <IconButton
        appearance="subtle"
        disabled={loading}
        icon={<Icon icon="unlink" />}
        loading={loading}
        onClick={setModalState}
        style={{ marginLeft: 10 }}
      />
    </span>} icon="google" />
    <DataRow
      content="User ID"
      data={oauth.userId}
    />
    <DataRow
      content="Name"
      data={oauth?.data?.name}
    />
    <DataRow
      content="Email"
      data={oauth?.data?.email}
    />
    <DataRow
      content="Device IDs"
      data={isString(oauth.deviceIds) ? oauth.deviceIds :
        (isArray(oauth.deviceIds) ? oauth.deviceIds.map((deviceId, i) => (<div key={i}>{deviceId}</div>)) : '-')
      }
    />
  </div>
)

export default (userWithUpdate: UserWithUpdate) => {
  const { id, oauth } = userWithUpdate

  const [loading, setLoading] = useState(SocialOAuths.EMPTY)
  const [modalState, setModalState] = useState(SocialOAuths.EMPTY)

  const unlinkOAuth = async (type: SocialOAuths) => {
    setLoading(type)

    try {
      await api.delete(`/dashboard/accounts/${id}/unlinkOauth/${modalState}`)

      const newUserInfo = omit(userWithUpdate, 'setUsersInfo')
      delete newUserInfo.oauth[modalState]
      userWithUpdate.setUsersInfo([newUserInfo])

      setModalState(SocialOAuths.EMPTY)
      Alert.success('User OAuth unlinked', 4000)
    } catch (ex) {
      Alert.error('An error occured when Unlinking User OAauth, please try again later', 4000)
    } finally {
      setLoading(SocialOAuths.EMPTY)
    }
  }

  return (
    <Fragment>
      {!isUndefined(oauth?.apple) && (<AppleOAauth
        loading={loading === SocialOAuths.APPLE}
        oauth={oauth.apple}
        setModalState={() => setModalState(SocialOAuths.APPLE)}
      />)}
      {!isUndefined(oauth?.facebook) && (<FacebookOAauth
        loading={loading === SocialOAuths.FACEBOOK}
        oauth={oauth.facebook}
        setModalState={() => setModalState(SocialOAuths.FACEBOOK)}
      />)}
      {!isUndefined(oauth?.google) && (<GoogleOAauth
        loading={loading === SocialOAuths.GOOGLE}
        oauth={oauth.google}
        setModalState={() => setModalState(SocialOAuths.GOOGLE)}
      />)}
      <Modal
        size="xs"
        show={modalState !== SocialOAuths.EMPTY}
      >
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: '#ffb300',
              fontSize: 24,
              marginRight: 10,
            }}
          />
          This will remove this social login from the user’s account and log out of any sessions using this social login.
          Are you sure you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            loading={loading !== SocialOAuths.EMPTY}
            onClick={() => unlinkOAuth(modalState)}
          >Unlink</Button>
          <Button
            appearance="default"
            onClick={() => setModalState(SocialOAuths.EMPTY)}
          >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
