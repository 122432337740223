import React, { useState } from 'react'
import { Icon, IconButton, Input, InputGroup, Panel, PanelGroup, Table } from 'rsuite'
import isNumber from 'lodash/isNumber'

import FilterPicker from 'components/FilterPicker'
import DataTable from 'components/users/History/DataTable'
import { formatDate, parseData } from 'util/utils'

import TasksActions from './TasksActions'
import useFetchAccountHistory from './useFetchAccountHistory'
import { User, UserTask } from '../types'

const DATA_COLUMNS = [
  { dataKey: 'id', header: 'ID', fixed: true, width: 240 },
  { dataKey: 'task_source', header: 'Source Type', width: 150 },
  { dataKey: 'task_type', header: 'Type', width: 150 },
  { dataKey: 'taskTitle', header: 'Task Title', width: 350 },
  { dataKey: 'taskStatus', header: 'Status', width: 10 },
  { dataKey: 'progress', header: 'Progress', width: 90, notSortable: true },
  { dataKey: 'started', header: 'Started At', width: 180 },
  { dataKey: 'completed_at', header: 'Completed At', width: 180, notSortable: true },
  { dataKey: 'bolts_reward', header: 'Bolts Reward', width: 120, notSortable: true },
  { dataKey: 'udid', header: 'UDID', width: 330 },
]

const TASK_TYPES = {
  offer: 'offer',
  survey: 'survey',
  video: 'video',
}

export default (user: User) => {
  const accountId = user.id

  const [fetchTimestamp, setFetchTimestamp] = useState(Date.now())
 
  const {
    data, filter, length, loading, page, query, sortKey, wordWrap,
    setFilter, setLength, setPage, setQuery, setSort, setWordWrap,
  } = useFetchAccountHistory(
    fetchTimestamp,
    accountId,
    '/dashboard/task-logs',
    '_id,task_source,task_type,tasks.offer_info,tasks.task_id,tasks.title,tasks.status,tasks.steps,steps,started,device_id',
    'Tasks',
  )

  const parsedData = parseData(data.data, query, (item: UserTask) => {
    const completedSteps = item.steps.filter(({ completed }) => completed)
    const isCompleted = completedSteps.length === item.steps.length
    const lastCompleted = isCompleted && completedSteps.sort((a, b) => b.completed.localeCompare(a.completed))[0]
    return {
      id: item._id,
      task_source: item.task_source,
      task_type: item.task_type,
      taskId: item.tasks.task_id,
      taskTitle: item.tasks.title,
      taskStatus: item.tasks.status,
      progress: item.task_type === 'video' ? '1/1' : `${completedSteps.length}/${item.steps.length}`, // no sorting
      started: formatDate(item.started),
      completed_at: lastCompleted ? formatDate(lastCompleted.completed) : '', // no sorting
      bolts_reward: item.steps.reduce((total, { bolts }) => (isNumber(bolts) ? total + bolts : total), 0), // no sorting
      udid: item.device_id,
      steps: item.steps,
      offerInfo: item.tasks.offer_info,
      offerSteps: item.tasks.steps,
    }
  })

  return (
    <PanelGroup className="container-users-history-table container-users-history-tasks">
      <Panel>
        <InputGroup inside style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search for anything in current results"
            onChange={(value) => setQuery(value)}
          />
          <FilterPicker
            data={TASK_TYPES}
            menuWidth={130}
            noCamelCaseParse
            onChange={(task_type) => setFilter({ ...filter, task_type })}
            placeholder="Type"
          />
          <IconButton
            active={wordWrap}
            disabled={loading}
            icon={<Icon icon="text-height" />}
            onClick={() => setWordWrap(!wordWrap)}
            style={{ marginLeft: 10 }}
          />
          <IconButton
            disabled={loading}
            icon={<Icon icon="refresh" />}
            onClick={() => setFetchTimestamp(Date.now())}
            style={{ marginLeft: 10 }}
          />
        </InputGroup>

        <DataTable
          accountId={accountId}
          actionColumnItems={TasksActions}
          columns={DATA_COLUMNS}
          data={parsedData}
          loading={loading}
          setFetchTimestamp={setFetchTimestamp}
          setSort={setSort}
          sortColumn={sortKey}
          user={user}
          wordWrap={wordWrap}
        />

        <Table.Pagination
          activePage={page}
          className="container-users-history-pagination"
          displayLength={length}
          total={data.meta.totalCount}
          onChangePage={(value) => setPage(value)}
          onChangeLength={(value) => setLength(value)}
          renderTotal={(total: number) => `Total: ${total || 0}, Visible: ${data.data.length}`}
        />
      </Panel>
    </PanelGroup>
  )
}
