import React, { useEffect, useState } from 'react'
import { Alert, Loader } from 'rsuite'

import DataHeader from 'components/DataHeader'
import api from 'util/api'
import { formatDate } from 'util/utils'

interface AccountSessionsProps {
  accountId: string
}

export interface AccountSessionData {
  createdTs: string
  devicename: string
  requestcountry: string
  requestip: string
  systemName: string
  udid: string
  'user-agent': string
  version: string
}

const DEFAULT_SIGN_UP_DATA: AccountSessionData[] = []

export default ({ accountId }: AccountSessionsProps) => {
  const [sessions, setSessions] = useState(DEFAULT_SIGN_UP_DATA)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getSignUpData = async () => {
      setLoading(true)
  
      try {
        const { data } = await api.get(`/dashboard/accounts/${accountId}/sessions`)
        try {
          setSessions(data.payload)
        } catch (ex) {
          setSessions([])
        }
      } catch (ex) {
        Alert.error('An error occured when fetching account sessions, please try again later', 4000)
      } finally {
        setLoading(false)
      }
    }

    getSignUpData()
  }, [accountId])

  return (
    <div style={{ marginTop: 20 }}>
      <DataHeader component="Active Sessions" icon="history" />
      {loading ?
        <Loader style={{ marginTop: 10 }} /> :
        (sessions.length ? sessions.map((session, id) => (
          <div key={id} style={{ marginBottom: 5 }}>
            <b>#{id}</b> created at {formatDate(session['createdTs']) || 'N/A'},&nbsp;
            <b>Devicename:</b> {session['devicename'] || 'N/A'},&nbsp;
            <b>Systemname:</b> {session['systemName'] || 'N/A'},&nbsp;
            <b>Version:</b> {session['version'] || 'N/A'},&nbsp;
            <b>Country:</b> {session['requestcountry'] || 'N/A'},&nbsp;
            <b>IP:</b> {session['requestip'] ||'N/A' }
            <br />
            <b>UA:</b> {session['user-agent'] || 'N/A'}
          </div>
        )) : 'No active sessions.')}
    </div>
  )
}
