import React, { useEffect, useState, Fragment } from 'react'
import { Alert, Loader } from 'rsuite'

import DataHeader from 'components/DataHeader'
import DataRow from 'components/DataRow'
import api from 'util/api'
import { formatDate } from 'util/utils'

interface SignUpDataProps {
  accountId: string
}

export interface UserSignUpData {
  createdTs: string
  devicename: string
  requestcountry: string
  requestip: string
  udid: string
  'user-agent': string
}

const DEFAULT_SIGN_UP_DATA: UserSignUpData[] = []

export default ({ accountId }: SignUpDataProps) => {
  const [[signUpData], setSignUpData] = useState(DEFAULT_SIGN_UP_DATA)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getSignUpData = async () => {
      setLoading(true)
  
      try {
        const { data } = await api.get(
          `/dashboard/logs?filter=accountId:eq:${accountId}&fields=data&sort=createdAt:asc&limit=1&page=1`
        )
        try {
          setSignUpData([data.data[0].data.signUpData])
        } catch (ex) {
          setSignUpData([])
        }
      } catch (ex) {
        Alert.error('An error occured when fetching Sign Up Data, please try again later', 4000)
      } finally {
        setLoading(false)
      }
    }

    getSignUpData()
  }, [accountId])

  return (
    <div style={{ marginTop: 20 }}>
      <DataHeader component="Signup Data" icon="user-plus" />
      {loading ?
        <Loader style={{ marginTop: 10 }} /> :
        (signUpData ? <Fragment>
          <DataRow content="Created Timestamp" data={formatDate(signUpData.createdTs)} />
          <DataRow content="UDID" data={signUpData.udid} />
          <DataRow content="Country" data={signUpData.requestcountry} />
          <DataRow content="IP" data={signUpData.requestip} />
        </Fragment> : 'This account does not have Sign Up Data')}
    </div>
  )
}
