import React, { useEffect, useState, Fragment } from 'react'
import { Alert, Loader } from 'rsuite'
import BigNumber from 'bignumber.js'

import DataRow from 'components/DataRow'
import api from 'util/api'
import { numberWithCommas } from 'util/utils'

interface BalanceProps {
  accountId: string
}

const useFetchAccountBalance = (accountId: string) => {
  const [balance, setBalance] = useState({
    bolt: {
      balance: '0',
      pending: '0',
    },
    cryptoBackUSD: {
      balance: '0',
      pending: '0',
    },
    referralUSD: {
      balance: '0',
      pending: '0',
    },
    stormxStaking: {
      balance: '0',
      pending: '0',
    },
  })
  const [loading, setLoading] = useState(false)
 
  useEffect(() => {
    const getBalance = async () => {
      setLoading(true)
  
      try {
        const currencies = '1,13,14,15'
        const { data: { payload } } = await api.get(`/dashboard/balance?account_id=${accountId}&currencies=${currencies}`)
        setBalance(payload)
      } catch (ex) {
        Alert.error('An error occured when fetching Account balance, please try again later', 4000)
      } finally {
        setLoading(false)
      }
    }

    getBalance()
  }, [accountId])
 
  return { balance, loading }
}

export default ({ accountId }: BalanceProps) => {
  const { balance, loading } = useFetchAccountBalance(accountId)
  const { bolt, cryptoBackUSD, referralUSD, stormxStaking } = balance

  const boltsBalance = new BigNumber(bolt.balance).toFixed(2)
  const pendingBoltsBalance = new BigNumber(bolt.pending).toFixed(2)
  const stmxBalance = new BigNumber(cryptoBackUSD.balance).plus(referralUSD.balance).plus(stormxStaking.balance).toFixed(2)
  const pendingSTMXBalance = new BigNumber(cryptoBackUSD.pending).plus(referralUSD.pending).plus(stormxStaking.pending).toFixed(2)

  const usdBalance = `$${numberWithCommas(boltsBalance)}`
  const pendingUsdBalance = `$${numberWithCommas(pendingBoltsBalance)}`
  const usdStmx = `$${numberWithCommas(stmxBalance)}`
  const pendingUsdStmx = `$${numberWithCommas(pendingSTMXBalance)}`

  return (<Fragment>
    <DataRow content="Balance" data={loading ? <Loader /> : usdBalance} />
    <DataRow content="Pending Balance" data={loading ? <Loader /> : pendingUsdBalance} />
    <DataRow content="STMX Balance" data={loading ? <Loader /> : usdStmx} />
    <DataRow content="Pending STMX Balance" data={loading ? <Loader /> : pendingUsdStmx} />
  </Fragment>)
}
