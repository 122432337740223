import React, { useState } from 'react'
import { Alert } from 'rsuite'

import { SearchFetchQuery, User } from './types'

import BasicInfo from './Info/Basic'
import DetailedInfo from './Info/Detailed'
import History from './History'
import Search from './Search'
import UsersList from './UsersList'

import api from 'util/api'
import { serializeQuery, setTitle } from 'util/utils'

const DEFAULT_USERS_INFO: User[] = []

const USERS_ROUTE = '/users'

export default () => {
  setTitle('Users')

  const [loading, setLoading] = useState(false)
  const [didSearch, setDidSearch] = useState(false)
  const [usersInfo, setUsersInfo] = useState(DEFAULT_USERS_INFO)

  const fetchAccounts = async (fetchQuery: SearchFetchQuery) =>{
    setLoading(true)

    try {
      const { data } = await api.get(`/dashboard/accounts?${serializeQuery(fetchQuery)}`)
      setUsersInfo(data.payload)
      if (!didSearch) {
        setDidSearch(true)
      }
    } catch (ex) {
      Alert.error('An error occured when fetching the accounts, please try again later', 4000)
    } finally {
      setLoading(false)
    }
  }

  return (<div className="container-users">
    <Search
      fetchAccounts={fetchAccounts}
      loading={loading}
    />

    {usersInfo.length === 1 && (<div className="container-users-single">
      <BasicInfo
        loading={loading}
        setUsersInfo={setUsersInfo}
        user={usersInfo[0]}
      />
      <br />
      {!loading && (<DetailedInfo
        loading={loading}
        setUsersInfo={setUsersInfo}
        user={usersInfo[0]}
      />)}
      <br />
      {!loading && usersInfo[0] && (<History
        loading={loading}
        user={usersInfo[0]}
      />)}
    </div>)}
    
    {usersInfo.length > 1 && (<div className="container-users-many">
      <UsersList
        loading={loading}
        setUsersInfo={setUsersInfo}
        users={usersInfo}
      />
    </div>)}

    {usersInfo.length === 0 && didSearch && (<div>
      No users were found, try using different search query
    </div>)}
  </div>)
}

export { USERS_ROUTE }
