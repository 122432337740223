import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { DEFAULT_ROUTE } from 'containers'

interface PublicRouteProps {
  authenticated: boolean
  component: React.ComponentType<any>
  exact: boolean
  path: string
}

export default ({ component: Component, authenticated, ...rest }: PublicRouteProps) => (
  <Route
    {...rest}
    render={(props) => authenticated === false
      ? <Component {...props} />
      : <Redirect to={{ pathname: DEFAULT_ROUTE, state: { from: props.location } }} />}
  />
)
