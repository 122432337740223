import { useEffect, useState } from 'react'
import { Alert } from 'rsuite'

import api from 'util/api'
import { buildFetchQuery } from 'util/utils'

import { APIDataPayload, SortData } from '../types'

const DEFAULT_DATA: APIDataPayload = {
  data: [],
  meta: {},
}

const DEFAULT_SORT: SortData = {
  sortKey: 'id',
  sortType: 'desc',
}

export default (
  fetchTimestamp: number,
  accountId: string,
  apiUrl: string,
  fields: string,
  type: string,
  camelCaseAccountId = false,
  defaultSort = DEFAULT_SORT,
) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(DEFAULT_DATA)

  const [length, setLength] = useState(50)
  const [page, setPage] = useState(1)
  const [{ sortKey, sortType }, setSort] = useState(defaultSort)
  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState({})
  const [wordWrap, setWordWrap] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const sort = `${sortKey}:${sortType}`
        const accountIdObject = camelCaseAccountId ? { accountId } : { account_id: accountId }
        const fetchQuery = buildFetchQuery({ ...filter, ...accountIdObject }, fields, sort, length, page)

        const { data } = await api.get(`${apiUrl}?${fetchQuery}`)
        setData(data)
      } catch (ex) {
        Alert.error(`An error occured when getting ${type}, please try again later or contact the tech team`, 5000)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [fetchTimestamp, accountId, apiUrl, fields, type, filter, length, page, sortKey, sortType, camelCaseAccountId])

  return {
    data, filter, length, loading, page, query, sortKey, wordWrap,
    setLength, setFilter, setPage, setQuery, setSort, setWordWrap,
  }
}
