import React from 'react'
import { CheckPicker, CheckPickerProps } from 'rsuite'
import omit from 'lodash/omit'

import { parseCamelCase } from 'util/utils'

interface FilterPicker extends CheckPickerProps {
  data: any
  menuWidth?: number
  noCamelCaseParse?: boolean
}

export default (props: FilterPicker) => (
  <CheckPicker
    style={{
      maxWidth: 220,
      marginLeft: 10,
    }}
    {...omit(props, ['menuWidth', 'noCamelCaseParse'])}
    menuStyle={{ width: props?.menuWidth || undefined }}
    onChange={props.onChange}
    placeholder={props.placeholder}
    searchable={false}
    data={Object.keys(props.data)
      .map((value) => ({
        label: props.noCamelCaseParse ? value : parseCamelCase(value),
        value: props.data[value],
      })
    )}
  />
)
