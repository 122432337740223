import React, { useState, Fragment } from 'react'
import { Alert, Button, Drawer, Icon, IconButton, Loader, Slider, Table, Tooltip, Whisper } from 'rsuite'
import { useRecoilValue } from 'recoil'

import api from 'util/api'
import { auth } from 'util/firebase'
import { remoteConfigState } from 'util/state'
import { boltsToUSD, formatDate, numberWithCommas } from 'util/utils'

interface OfferStep {
  bolts: number
  step_action: {
    created_date: string
    goal_id: number
    offer_id: number
  }
  step_id: string
  step_name: string
  step_type: string
}

interface TaskDetailsStep {
  completedAt: string | null | undefined
  id: string
  name: string
  percentage: string
  reward: string
  rewardAwarded: string | null | undefined
  stepId: string
  stepName: string
}

interface TaskStep {
  bolts: number | null
  completed: string | null
  status: string | null
  step_id: string
  step_type: string
  user_action_data?: {
    city: string
    country: string
    countryName: string
    region: string
    regionCode: string
  }
}

const parseTaskDetails = (USD_IN_BOLTS: number, offerSteps: OfferStep[], steps: TaskStep[]): TaskDetailsStep[] =>
  offerSteps.map(({ bolts, step_id, step_name }) => {
    const taskStep = steps.find((taskStep) => taskStep.step_id === step_id)
    return {
      completedAt: taskStep?.completed ? formatDate(taskStep.completed) : '',
      id: step_id,
      name: step_name,
      percentage: `${taskStep?.bolts ? parseFloat((taskStep.bolts / bolts).toFixed(2)) * 100 : 0}%`,
      reward: `${bolts} Bolts`,
      rewardUsd: `$${numberWithCommas(boltsToUSD(USD_IN_BOLTS, bolts.toString()))}`,
      rewardAwarded: `${taskStep?.bolts || 0} Bolts`,
      rewardAwardedUsd: `$${numberWithCommas(boltsToUSD(USD_IN_BOLTS, (taskStep?.bolts || 0).toString()))}`,
      stepId: step_id,
      stepName: step_name,
    }
  })

const TasksActionsResolve = ({
  accountId,
  setExpandedRowKey,
  setFetchTimestamp,
  taskId,
  taskDetails,
  taskName,
}: {
  accountId: string
  setExpandedRowKey: React.Dispatch<React.SetStateAction<string>>
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  taskId: string
  taskDetails: TaskDetailsStep
  taskName: string
}) => {
  const { USD_IN_BOLTS } = useRecoilValue(remoteConfigState)

  const [loading, setLoading] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const amount = parseInt((parseInt(taskDetails.reward) * (percentage / 100)).toString())
  const amountUsd = boltsToUSD(USD_IN_BOLTS, amount.toString())

  const resolveTaskStep = async () =>{
    setLoading(true)

    try {
      const { stepId, stepName } = taskDetails
      const { data: { success } } = await api.post(`/dashboard/resolve-task`, {
        accountId,
        amount,
        percentage,
        repEmail: auth().currentUser?.email,
        stepId,
        stepName,
        taskId,
        taskName,
        ua: navigator.userAgent,
      })
      if (success) {
        Alert.success('Task step has been successfully resolved', 4000)
      }
      setExpandedRowKey('')
      setFetchTimestamp(Date.now())
    } catch (ex) {
      Alert.error('An error occured when resolving the task, please try again later', 4000)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (<Loader
      center
      size="md"
    />)
  }

  return (
    <Fragment>
      <Slider
        defaultValue={percentage}
        graduated
        max={100}
        min={0}
        onChange={(percentage) => setPercentage(percentage)}
        progress
        renderMark={(mark) => mark}
        step={25}
        style={{ margin: '5px 20px 5px 20px' }}
      />
      <div style={{
        float: 'left',
        marginTop: 35,
      }}>
        Reward ({percentage}% of full ${numberWithCommas(boltsToUSD(USD_IN_BOLTS, taskDetails.reward))}):
        <strong style={{ marginLeft: 5 }}>
          ${numberWithCommas(amountUsd)}
        </strong>
      </div>
      <div style={{
        float: 'right',
        marginTop: 30,
      }}>
        <Button
          appearance="primary"
          onClick={() => resolveTaskStep()}
          size="xs"
          style={{ marginRight: 10 }}
        >Resolve</Button>
        <Button
          onClick={() => setExpandedRowKey('')}
          size="xs"
        >Cancel</Button>
      </div>
    </Fragment>
  )
}

const TasksActionsTooltip = ({ children, tooltip }: { children?: any, tooltip: string }) => (
  <Whisper
    placement="top"
    trigger="hover"
    speaker={<Tooltip>{tooltip}</Tooltip>}
  >
    {children}
  </Whisper>
)

const TasksDetailsDrawer = ({
  accountId,
  loading,
  offerInfo,
  onHide,
  setFetchTimestamp,
  show,
  taskId,
  taskDetails,
  taskStatus,
  taskTitle,
}: {
  accountId: string
  loading: boolean
  offerInfo: any
  onHide: () => any
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  show: boolean
  taskId: string
  taskDetails: TaskDetailsStep[]
  taskStatus: string
  taskTitle: string
}) => {
  const [expandedRowKey, setExpandedRowKey] = useState('')

  return (
    <Drawer
      onHide={onHide}
      onExited={() => setExpandedRowKey('')}
      placement="right"
      size="lg"
      show={show}
    >
      <Drawer.Header>
        <Drawer.Title>
          {taskTitle} (Status: {taskStatus})
          <TaskEverflowLink offerId={offerInfo.network_offer_id}>
            <span style={{ marginLeft: 5 }}>
              View on Everflow
              <Icon icon="external-link" style={{ marginLeft: 5 }} />
            </span>
          </TaskEverflowLink>
          {loading && (<Loader />)}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Table
          autoHeight
          data={taskDetails}
          expandedRowKeys={[expandedRowKey]}
          hover={false}
          renderRowExpanded={(taskDetails: TaskDetailsStep) => (
            <TasksActionsResolve
              accountId={accountId}
              setFetchTimestamp={setFetchTimestamp}
              taskId={taskId}
              taskDetails={taskDetails}
              taskName={taskTitle}
              setExpandedRowKey={setExpandedRowKey}
            />
          )}
          rowKey="id"
          wordWrap
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Goal Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>
          <Table.Column width={120}>
            <Table.HeaderCell>Reward</Table.HeaderCell>
            <Table.Cell dataKey="rewardUsd" />
          </Table.Column>
          <Table.Column width={120}>
            <Table.HeaderCell>Reward Awarded</Table.HeaderCell>
            <Table.Cell dataKey="rewardAwardedUsd" />
          </Table.Column>
          <Table.Column width={100}>
            <Table.HeaderCell>Percentage</Table.HeaderCell>
            <Table.Cell dataKey="percentage" />
          </Table.Column>
          <Table.Column width={180}>
            <Table.HeaderCell>Completed At</Table.HeaderCell>
            <Table.Cell dataKey="completedAt" />
          </Table.Column>
          <Table.Column width={80}>
            <Table.HeaderCell>Actions</Table.HeaderCell>
            <Table.Cell>
              {(taskDetails: TaskDetailsStep) =>
                !taskDetails.completedAt ? (
                  <span
                    onClick={() => {
                      setExpandedRowKey(expandedRowKey === taskDetails.id ? '' : taskDetails.id)
                    }}
                    style={{
                      cursor: 'pointer',
                      fontSize: '80%',
                    }}
                  >Resolve</span>
                ) : '-'
              }
            </Table.Cell>
          </Table.Column>
        </Table>
      </Drawer.Body>
    </Drawer>
  )
}

const TaskEverflowLink = ({ children, offerId }: { children?: any, offerId: number }) => (
  <a
    href={`https://stormx.everflowclient.io/offers/${offerId}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
)

export default ({
  accountId,
  loading,
  offerInfo,
  offerSteps,
  setFetchTimestamp,
  steps,
  taskId,
  taskStatus,
  taskTitle,
}: {
  accountId: string
  loading: boolean
  offerInfo: any
  offerSteps: OfferStep[]
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  steps: TaskStep[]
  taskId: string
  taskStatus: string
  taskTitle: string
}) => {
  const { USD_IN_BOLTS } = useRecoilValue(remoteConfigState)

  const [showDetails, setShowDetails] = useState(false)

  const taskDetails = parseTaskDetails(USD_IN_BOLTS, offerSteps, steps)

  if (!offerInfo) {
    return (<Fragment>-</Fragment>)
  }

  return (
    <Fragment>
      <TasksDetailsDrawer
        accountId={accountId}
        loading={loading}
        offerInfo={offerInfo}
        onHide={() => setShowDetails(false)}
        setFetchTimestamp={setFetchTimestamp}
        show={showDetails}
        taskId={taskId}
        taskDetails={taskDetails}
        taskStatus={taskStatus}
        taskTitle={taskTitle}
      />
      <TasksActionsTooltip tooltip="Task Details">
        <IconButton
          icon={<Icon icon="cog" />}
          onClick={() => setShowDetails(!showDetails)}
          style={{ margin: '-8px 5px 0 0' }}
        />
      </TasksActionsTooltip>
    </Fragment>
  )
}
