import { transaction } from '@stormx/const'
import React, { useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
import { Alert, Button, Icon, IconButton, InputNumber, Input, Modal, SelectPicker } from 'rsuite'

import { User, UserStatus } from '../types'
import api from 'util/api'
import { auth } from 'util/firebase'
import { ratesState, remoteConfigState } from 'util/state'
import { usdToBolts, usdToSTMX } from 'util/utils'

const { currency, txSourceType, txType } = transaction

const CREDIT_TYPES: string[] = [
  'General Credit',
  'Missing Reward',
  //'STMX Credit',
]

const CURRENCY_MAP = [
  currency.bolt,
  currency.bolt,
  currency.stormx,
]

export default (user: User) => {
  const { stormx } = useRecoilValue(ratesState)
  const { USD_IN_BOLTS } = useRecoilValue(remoteConfigState)

  const [type, setType] = useState('')
  const [amount, setAmount] = useState(0)
  const [reason, setReason] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [creditLoading, setCreditLoading] = useState(false)

  const creditCurrency = CURRENCY_MAP[CREDIT_TYPES.indexOf(type)]
  let amountPostfix = null
  if (creditCurrency === currency.stormx) {
    amountPostfix = `${usdToSTMX(stormx || 0, amount.toString())} STMX`
  }

  const clearFields = () => {
    setType('')
    setAmount(0)
    setReason('')
  }

  const creditUser = async () => {
    setCreditLoading(true)

    let creditAmount = '0'
    if (creditCurrency === currency.bolt) {
      creditAmount = usdToBolts(USD_IN_BOLTS, amount.toString())
    } else if (creditCurrency === currency.stormx) {
      creditAmount = usdToSTMX(stormx || 0, amount.toString())
    }

    try {
      await api.post('/transaction', {
        accountId: user.id,
        amount: creditAmount,
        country: 'US',
        currencyId: creditCurrency,
        ip: 'dashboard.stormx.io',
        note: `${reason} - ${type} - by ${auth().currentUser?.email} (id ${auth().currentUser?.uid})`,
        source: txSourceType.customerService,
        type: txType.credit,
        ua: 'browser',
      })

      setShowModal(false)
      Alert.success('User credit sent', 4000)
    } catch (ex) {
      Alert.error('An error occured when sending User Credit, please try again later', 4000)
    } finally {
      setCreditLoading(false)
    }
  }

  return (
    <Fragment>
      <span
        className="element-interaction"
        onClick={() => setShowModal(!showModal)}
      >
        {<IconButton icon={<Icon icon="twinkle-star" />}>Credit User</IconButton>}
      </span>
      <Modal
        onExited={() => clearFields()}
        show={showModal}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Credit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectPicker
            block
            cleanable={false}
            data={CREDIT_TYPES
              .map((value) => ({
                label: value,
                value: value,
              })
            )}
            disabled={creditLoading}
            placeholder="Reward Type"
            onChange={(status: UserStatus) => setType(status)}
            searchable={false}
          />
          {type && (<InputNumber
            disabled={creditLoading}
            onChange={(value) => setAmount(+value)}
            placeholder="Amount"
            postfix={amountPostfix}
            prefix="$"
            step={0.01}
            style={{ marginTop: 15 }}
          />)}
          {amount > 0 && (<Input
            componentClass="textarea"
            disabled={creditLoading}
            onChange={(value) => setReason(value)}
            placeholder="Reason"
            style={{
              marginTop: 15,
              resize: 'vertical',
            }}
          />)}
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            disabled={!type || !amount || !reason}
            loading={creditLoading}
            onClick={() => creditUser()}
          >Credit</Button>
          <Button
            appearance="default"
            onClick={() => setShowModal(false)}
          >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
