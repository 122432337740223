import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import startCase from 'lodash/startCase'

import { StormXRewardsLevel } from 'util/state'

interface FiltersQuery {
  [key: string]: any
}

const BASE_TITLE = document.getElementsByTagName('head')[0].getElementsByTagName('title')[0].innerText

export const boltsToUSD = (USD_IN_BOLTS: number, value: string, multiplier = 1): string =>
  new BigNumber(parseInt(value)).multipliedBy(multiplier).dividedBy(USD_IN_BOLTS).toFixed(2)

export const buildFetchQuery = (
  filters: FiltersQuery,
  fields: string,
  sort: string,
  limit: number,
  page: number,
): string => {
  let filter: string[] = []
  Object.keys(filters).forEach((key) => {
    if (isArray(filters[key]) && filters[key].length) {
      filter.push(`${key}:in:[${filters[key].join(',')}]`)
    } else {
      filter.push(`${key}:eq:${filters[key]}`)
    }
  })
  return `filter=${filter.join(',')}&fields=${fields}&sort=${sort}&limit=${limit}&page=${page}`
}

export const clearLocationHash = () => {
  try {
    window.history.replaceState(null, '', ' ')
  } catch (ex) {}
}

export const formatDate = (date: Date | string): string => {
  if (!date) {
    return '-'
  }
  if (isString(date)) {
    date = new Date(date)
  }
  return format(date, 'MM/dd/yyyy hh:mm:ss aaa')
}

export const getRewardsLevel = (
  stormxRewards: StormXRewardsLevel[],
  accountLevel: number,
): StormXRewardsLevel | undefined => {
  return stormxRewards.find(({ level }) => level === accountLevel)
}

export const getRewardsLevelName = (stormxRewards: StormXRewardsLevel[], accountLevel: number): string => {
  const rewardsLevel = stormxRewards.find(({ level }) => level === accountLevel)
  return rewardsLevel ? rewardsLevel.name : 'None'
}

export const numberWithCommas = (x: number | string): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const parseCamelCase = (str: string): string => startCase(str)

export const parseData = (
  data: any[],
  query: string,
  mapFunction: (value: any) => any,
): any[] => {
  data = data.map(mapFunction)

  if (query && data.length) {
    const keys = Object.keys(data[0])
    query = query.toLowerCase()

    data = data.filter((item: any) => {
      for (let i = 0; i < keys.length; i++) {
        const value = item[keys[i]]?.toString().toLowerCase()
        if (value && value.includes(query)) {
          return true
        }
      }
      return false
    })
  }

  return data
}

export const parseParams = (value: string): Record<string, string> =>
  value.length ? value
    .replace(value.startsWith('#') ? '#' : '', '')
    .replace(value.startsWith('?') ? '?' : '', '')
    .split('&')
    .map(v => v.split('='))
    .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} ) : {}

export const serializeQuery = (obj: { [key: string]: any }): string => {
  var str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  }
  return str.join('&')
}

export const setHashParam = (key: string, value: string) => {
  const params = parseParams(window.location.hash)
  params[key] = value
  window.location.hash = serializeQuery(params)
}

export const setTitle = (title: string): string => document.title = `${BASE_TITLE} - ${title}`

export const stmxToUSD = (stmxRate: number, value: string, multiplier = 1): string =>
  new BigNumber(value).multipliedBy(multiplier).multipliedBy(stmxRate).toFixed(2)

export const usdToBolts = (USD_IN_BOLTS: number, value: string, multiplier = 1): string =>
  new BigNumber(value).multipliedBy(multiplier).multipliedBy(USD_IN_BOLTS).toString()

export const usdToSTMX = (rate: number, value: string, multiplier = 1): string =>
  new BigNumber(value).multipliedBy(multiplier).dividedBy(rate).toFixed(8)
