import React, { useState } from 'react'
import validator from 'validator'
import { Alert, Button, FlexboxGrid, Form, FormGroup, Input, Message } from 'rsuite'

import WordmarkBlack from 'images/wordmark-black.svg'
import WordmarkWhite from 'images/wordmark-white.svg'
import { auth } from 'util/firebase'
import { getValue, LS_DARK_MODE, LS_FIREBASE_EMAIL_KEY, setValue } from 'util/storage'
import { setTitle } from 'util/utils'

const LOGIN_ROUTE = '/login'

const actionCodeSettings = {
  url: `${window.location.origin}${LOGIN_ROUTE}`,
  handleCodeInApp: true
}

const STORMX_EMAIL = '@stormx.io'

const isDarkMode = true || getValue(LS_DARK_MODE) === 'true'

export default () => {
  setTitle('Login')

  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [wasSent, setWasSent] = useState(false)

  const onSubmit = (email: string) => {
    if (!email.includes(STORMX_EMAIL)) {
      email += STORMX_EMAIL
    }
    setIsLoading(true)

    return auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
      setWasSent(true)
      setValue(LS_FIREBASE_EMAIL_KEY, email)
    }).catch((ex) => {
      Alert.error(ex.message || 'Unknown error occured, please contact the tech team', 4000)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (<FlexboxGrid justify="space-around">
    <FlexboxGrid.Item>
      <Form>
        <FormGroup style={{ textAlign: 'center' }}>
          <img
            src={isDarkMode ? WordmarkWhite : WordmarkBlack} 
            alt="StormX Dashboard"
            width={400}
            style={{ marginTop: 200 }}
          />
        </FormGroup>
        <br />
        {!wasSent ? <div>
          <FormGroup>
          <Input
            size="lg"
            placeholder="Enter your @stormx.io email address"
            value={email}
            onChange={value => setEmail(value)}
            disabled={isLoading}
          />
        </FormGroup>
        <FormGroup>
          <Button
            color="red"
            size="lg"
            onClick={() => onSubmit(email)}
            type="submit"
            disabled={!((validator.isEmail(email) && email.includes(STORMX_EMAIL))
              || validator.isEmail(`${email}${STORMX_EMAIL}`)) || isLoading}
            loading={isLoading}
            block
          >Send Login Link</Button>
        </FormGroup>
        </div> : <Message
          description="Authentication link was sent to your inbox, please click it to authorize."
          title="Success!"
          type="success"
        />}
      </Form>
    </FlexboxGrid.Item>
  </FlexboxGrid>)
}

export { LOGIN_ROUTE }
