const basePrefix = 'stormx-dashboard'

export const LS_DARK_MODE = `${basePrefix}-dark-mode`
export const LS_FIREBASE_EMAIL_KEY = `${basePrefix}-firebase-auth-email`
export const LS_SIDEBAR_COLLAPSED = `${basePrefix}-sidebar-collapsed`
export const LS_USERS_DETAILED_COLLAPSED= `${basePrefix}-users-detailed-collapsed`

export const removeValue = (key: string): void => {
  return window.localStorage.removeItem(key)
}

export const getValue = (key: string): string | null => {
  return window.localStorage.getItem(key)
}

export const setValue = (key: string, value: string): void => {
  return window.localStorage.setItem(key, value)
}
