import React, { useEffect, useState, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import { useRecoilValue } from 'recoil'
import {
  Alert,
  Button,
  ButtonToolbar,
  Col,
  DatePicker,
  Divider,
  Drawer,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  Input,
  InputNumber,
  List,
  Loader,
  PanelGroup,
  Row,
  Tooltip,
  Whisper,
} from 'rsuite'
import { isAfter, isBefore } from 'date-fns'

import { User, UserShopping } from 'components/users/types'
import api from 'util/api'
import { auth } from 'util/firebase'
import { remoteConfigState } from 'util/state'
import { boltsToUSD, getRewardsLevel, getRewardsLevelName, numberWithCommas, usdToBolts } from 'util/utils'

interface ShoppingActionsProps extends UserShopping {
  accountId: string
  loading: boolean
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  user: User
}

interface ShoppingDetailsDrawerProps extends ShoppingActionsProps {
  onHide: () => any
  show: boolean
}

interface ShoppingDetailsMetadata {
  resolvedManually?: boolean
  shopInfo?: {
    purchaseDate?: string
    shoppingCartAmount?: string
    shoppingCartAmountUsd?: string
  }
}

interface Offer {
  advertiser: string,
  advertiserInfo: {
    advertiserId: number
    name: string
    popularity: Record<string, number>
    _id: string
  }
  category: string
  createdAt: string
  description: string
  events: {
    name: string
    payoutType: string
    payoutAmount: number
    revenueType: string
    revenuePercentage: number
  }
  everflow: {
    encodedValue: string
    name: string
    networkAdvertiserId: number
    networkOfferId: number
    previewUrl: string
    timeCreated: string
    timeSaved: string
  }
  group: string
  id: string
  image: string
  source: string
  tc: string
  timesStarted: number
  title: string
  updatedAt: string
  targeting?: {
    createdAt: string
    endDate: string
    labels: string[]
    liveDate: string
    status: string
    targeting: {}
    taskGroup: string
    taskId: string
    taskName: string
    taskSource: string
    taskType: string
    updatedAt: string
  }
}

const pullOutLockinDays = (labels: string []) => {
  const lockinDays = labels.find((label) => label.includes('lockindays-'))
  if (lockinDays) {
    return lockinDays.replace('lockindays-', '')
  }
  return '-'
}

const OfferDetails = ({ offer }: { offer?: Offer }) => {
  if (offer) {
    return (
      <Fragment>
        <h5>Offer details:</h5>
        <strong>Advertiser</strong>: {offer.advertiser}<br/>
        <strong>Lock in days</strong>: {pullOutLockinDays(offer?.targeting?.labels || [])}<br/>
        <strong>TC</strong>: <span dangerouslySetInnerHTML={{ __html: offer.tc ? `<br />${offer.tc}` : '-' }} />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <Icon icon="exclamation-triangle" /> Could not get offer details
      </Fragment>
    )
  }
}

const ShoppingActionsResolve = ({
  accountId,
  amount,
  amountFormatted,
  createdTs,
  jsonMetadata,
  setFetchTimestamp,
  sourceId,
  user,
  uuid,
}: {
  accountId: string
  amount: string
  amountFormatted: string
  createdTs: string,
  jsonMetadata: ShoppingDetailsMetadata
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  sourceId: string
  metadata: string
  user: User
  uuid: string
}) => {
  const { STORMX_REWARDS, USD_IN_BOLTS } = useRecoilValue(remoteConfigState)

  const [{ bolts, usd }, setAmount] = useState({ bolts: amount, usd: boltsToUSD(USD_IN_BOLTS, amount) })
  const [expirationDays, setExpirationDays] = useState(90)
  const [note, setNote] = useState('')
  const [shoppingCartAmount, setShoppingCartAmount] =
    useState(jsonMetadata?.shopInfo?.shoppingCartAmountUsd ? jsonMetadata.shopInfo?.shoppingCartAmountUsd.toString() :
      (jsonMetadata?.shopInfo?.shoppingCartAmount ? jsonMetadata.shopInfo?.shoppingCartAmount.toString() : '0'))
  const [purchaseDate, setPurchaseDate] =
    useState(jsonMetadata?.shopInfo?.purchaseDate ? new Date(jsonMetadata.shopInfo?.purchaseDate) : undefined)
  const [loading, setLoading] = useState(false)
  const [offer, setOffer] = useState()
  const [offerLoading, setOfferLoading] = useState(false)
  const [resolved, setResolved] = useState(false)

  const higherAmount = new BigNumber(bolts).isLessThan(amount)
  const submitDisabled = higherAmount || !bolts || !expirationDays || !shoppingCartAmount || !purchaseDate || loading

  const cryptoBackBonus = getRewardsLevel(STORMX_REWARDS, user?.stormxRewards?.level)?.cryptoBackBonus || 1
  const cryptoBackTimeReduction =
    Math.abs((getRewardsLevel(STORMX_REWARDS, user?.stormxRewards?.level)?.cryptoBackTimeReduction || 0) - 1)

  const onAmountUSDChange = (value: string | number) =>
    setAmount({
      bolts: usdToBolts(USD_IN_BOLTS, value as string),
      usd: value.toString(),
    })

  const resolveShopping = async () =>{
    setLoading(true)

    try {
      const { data: { success } } = await api.post(`/dashboard/resolve-shopping`, {
        accountId,
        amount: parseInt(bolts),
        expirationDays,
        note: `${note ? `${note} - ` : ''}by ${auth().currentUser?.email} (id ${auth().currentUser?.uid})`,
        purchaseDate,
        shoppingCartAmount: parseFloat(shoppingCartAmount),
        uuid,
        ua: navigator.userAgent,
      })
      if (success) {
        Alert.success('Shopping has been successfully resolved', 4000)
        setResolved(true)
      }
      setFetchTimestamp(Date.now())
    } catch (ex) {
      Alert.error('An error occured when resolving the shopping, please try again later', 4000)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchOffer = async () =>{
      setOfferLoading(true)

      try {
        const { data } = await api.get(`/dashboard/offer/transactionId/${sourceId}`)
        if (data.success) {
          setOffer(data.payload)
        }
      } catch (ex) {
        Alert.error('An error occured when fetching the offer, please try again later', 4000)
      } finally {
        setOfferLoading(false)
      }
    }

    fetchOffer()
  }, [sourceId])

  if (resolved) {
    return (null)
  }

  if (loading) {
    return (
      <div style={{
        height: 230,
        position: 'relative',
      }}>
        <Divider>
          <b>Resolve Shopping Trip</b>
        </Divider>
        <Loader
          center
          size="md"
        />
      </div>
    )
  }

  return (
    <Grid fluid>
      <Divider>
        <b>Resolve Shopping Trip</b>
      </Divider>

      <PanelGroup
        bordered
        style={{
          position: 'absolute',
          padding: '10px 20px',
          right: 20,
          textAlign: 'right',
        }}
      >
        Account Reward Level: <strong>{getRewardsLevelName(STORMX_REWARDS, user?.stormxRewards?.level)}</strong>
        <br />
        Crypto Back Bonus: <strong>{cryptoBackBonus}</strong>
        <br />
        Expiration Time multiplier: <strong>{cryptoBackTimeReduction}</strong>
      </PanelGroup>

      <PanelGroup
        bordered
        style={{
          marginTop: 95,
          maxWidth: 400,
          position: 'absolute',
          padding: '10px 20px',
          right: 20,
          textAlign: 'right',
        }}
      >
        {offerLoading ? (<Loader />) : (<OfferDetails offer={offer} />)}
      </PanelGroup>

      <FormGroup style={{ marginBottom: 7 }}>
        Shopping Cart Amount:
        <div
          className="inline-input-wrapper"
          style={{
            marginLeft: 7,
            width: 140,
          }}
        >
          <InputNumber
            onChange={(shoppingCartAmount) =>
              setShoppingCartAmount(shoppingCartAmount.toString())
            }
            prefix="$"
            size="sm"
            step={1}
            value={shoppingCartAmount}
          />
        </div>
      </FormGroup>
      <FormGroup style={{ marginBottom: 7 }}>
        Crypto Cash Back Amount:
        <div
          className="inline-input-wrapper"
          style={{
            marginLeft: 7,
            marginRight: 7,
            width: 140,
          }}
        >
          <InputNumber
            onChange={onAmountUSDChange}
            prefix="$"
            size="sm"
            step={0.1}
            value={usd}
          />
        </div>
        {higherAmount && (<Fragment>
          <br />
          <span style={{ color: 'red' }}> Amount cannot be lass than current ${amountFormatted}</span>
        </Fragment>)}
      </FormGroup>
      <FormGroup style={{ marginBottom: 7 }}>
        Purchase Date:
        <DatePicker
          disabledDate={(date: Date | undefined) =>
            isBefore(date as Date, new Date(createdTs)) || isAfter(date as Date, new Date())
          }
          format="MM/DD/YYYY hh:mm:ss A"
          onChange={(purchaseDate) => setPurchaseDate(purchaseDate)}
          placement="auto"
          size="sm"
          style={{ marginLeft: 7 }}
          value={purchaseDate}
        />
      </FormGroup>
      <FormGroup style={{ marginBottom: 7 }}>
        Expiration Days:
        <div
          className="inline-input-wrapper"
          style={{
            marginLeft: 7,
            marginRight: 7,
            width: 100,
          }}
        >
          <InputNumber
            onChange={(expirationDays) => setExpirationDays(expirationDays ? parseInt(expirationDays as string) : 0)}
            size="sm"
            value={expirationDays}
          />
        </div>
      </FormGroup>
      <div style={{ marginTop: 7 }}>
        <Input
          onChange={(note) => setNote(note)}
          placeholder="Note (optional)"
          size="sm"
          style={{ width: 420 }}
          value={note}
        />
      </div>
      <div style={{ marginTop: 12 }}>Reward bonuses will be applied automatically.</div>
      <ButtonToolbar style={{ marginTop: 15 }}>
        <Button
          appearance="primary"
          disabled={submitDisabled}
          onClick={() => resolveShopping()}
          size="sm"
          style={{ marginRight: 10 }}
        >Resolve</Button>
      </ButtonToolbar>
    </Grid>
  )
}

const ShoppingActionsTooltip = ({ children, tooltip }: { children?: any, tooltip: string }) => (
  <Whisper
    placement="top"
    trigger="hover"
    speaker={<Tooltip>{tooltip}</Tooltip>}
  >
    {children}
  </Whisper>
)

const ShoppingDetailsItem = ({ label, value }: { label: string, value: any }) => (
  <List.Item>
    <Grid>
      <Row>
        <Col xs={6}>{label}</Col>
        <Col xs={18}>{value}</Col>
      </Row>
    </Grid>
  </List.Item>
)

const ShoppingDetailsJSONField = ({ value }: { value: string }) => (
  <pre style={{ fontSize: '80%' }}>{value}</pre>
)

const ShoppingDetailsDrawer = ({
  accountId,
  amount,
  brand_name,
  created_ts,
  expiration_ts,
  id,
  loading,
  metadata,
  onHide,
  setFetchTimestamp,
  show,
  source_id,
  source_sub_id,
  source_type_id,
  status_id,
  user,
  uuid,
}: ShoppingDetailsDrawerProps) => {
  const { USD_IN_BOLTS } = useRecoilValue(remoteConfigState)

  const usdSplit = boltsToUSD(USD_IN_BOLTS, amount).split('.')
  const usd = numberWithCommas(usdSplit[0])
  const cents = usdSplit[1]

  let parsedMetadata = '-'
  let jsonMetadata: ShoppingDetailsMetadata = {}
  try {
    jsonMetadata = JSON.parse(metadata as string)
    parsedMetadata = JSON.stringify(jsonMetadata, null, 2)
  } catch (ex) {}

  const isResolvable = ['Expired', 'Pending', 'Started'].includes(status_id) && !jsonMetadata.resolvedManually

  return (
    <Drawer
      onHide={onHide}
      placement="right"
      size="lg"
      show={show}
    >
      <Drawer.Header>
        <Drawer.Title>
          Shopping ID {id} (Status: {status_id})
          {loading && (<Loader />)}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <List size="sm">
          <ShoppingDetailsItem label="Brand name" value={brand_name} />
          <ShoppingDetailsItem label="Amount" value={`$${usd}.${cents} (${numberWithCommas(amount)} Bolts)`} />
          <ShoppingDetailsItem label="Started Timestamp" value={created_ts} />
          <ShoppingDetailsItem label="UUID" value={uuid} />
          <ShoppingDetailsItem label="Shopping Trip ID (Source ID)" value={source_id} />
          <ShoppingDetailsItem label="Source Sub ID" value={source_sub_id} />
          <ShoppingDetailsItem label="Source Type" value={source_type_id} />
          <ShoppingDetailsItem label="Expiration Timestamp" value={expiration_ts} />
          <ShoppingDetailsItem label="Metadata" value={<ShoppingDetailsJSONField value={parsedMetadata} />} />
        </List>
        {isResolvable && (<ShoppingActionsResolve
          accountId={accountId}
          amount={amount}
          amountFormatted={`${usd}.${cents}`}
          createdTs={created_ts}
          jsonMetadata={jsonMetadata}
          metadata={metadata as string}
          setFetchTimestamp={setFetchTimestamp}
          sourceId={source_id}
          user={user}
          uuid={uuid}
        />)}
      </Drawer.Body>
    </Drawer>
  )
}

export default (props: ShoppingActionsProps) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Fragment>
      <ShoppingDetailsDrawer
        {...props}
        onHide={() => setShowDetails(false)}
        show={showDetails}
      />
      <ShoppingActionsTooltip tooltip="Shopping Details">
        <IconButton
          icon={<Icon icon="cog" />}
          onClick={() => setShowDetails(!showDetails)}
          style={{ margin: '-8px 5px 0 0' }}
        />
      </ShoppingActionsTooltip>
    </Fragment>
  )
}
