import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'stormx-internal.firebaseapp.com',
  databaseURL: 'https://stormx-internal.firebaseio.com',
}
firebase.initializeApp(config)

export const auth = firebase.auth
