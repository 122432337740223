import React, { useEffect } from 'react'

export default () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('/')
    }, 3000)
  }, [])

  return (<div
    style={{
      fontSize: 24,
      marginTop: 200,
      textAlign: 'center',
    }}
  >
    This page does not exist.
    <br />
    You will be automatically redirected to the dashboard.
  </div>
)
}
