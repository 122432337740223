import React, { useState, Fragment } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { LOGIN_ROUTE } from 'components/login'
import Sidebar from 'containers/Sidebar'
import { getValue, setValue, LS_SIDEBAR_COLLAPSED } from 'util/storage'

const DEFAULT_EXPANDED = getValue(LS_SIDEBAR_COLLAPSED) !== 'true'

interface DashboardRouteProps {
  authenticated: boolean
  component: React.ComponentType<any>
  exact: boolean
  path: string
}

export default ({ component: Component, authenticated, ...rest }: DashboardRouteProps) => {
  const [expanded, setExpanded] = useState(DEFAULT_EXPANDED)

  const setExpandedBind = () => {
    setValue(LS_SIDEBAR_COLLAPSED, (expanded).toString())
    setExpanded(!expanded)
  }

  return (
    <Route
      {...rest}
      render={(props) => authenticated === true ?
        (<Fragment>
          <Sidebar expanded={expanded} setExpanded={setExpandedBind} />
          <div
            className="main-content"
            style={{ marginLeft: expanded ? 250 : 55 }}
          >
            <Component />
          </div>
        </Fragment>) :
        (<Redirect to={{ pathname: LOGIN_ROUTE, state: { from: props.location } }} />)
      }
    />
  )
}
