import { transaction } from '@stormx/const'
import React, { useState } from 'react'
import { Icon, IconButton, Input, InputGroup, Panel, PanelGroup, Table } from 'rsuite'

import FilterPicker from 'components/FilterPicker'
import DataTable from 'components/users/History/DataTable'
import { formatDate, parseCamelCase, parseData } from 'util/utils'

import ShoppingActions from './ShoppingActions'
import useFetchAccountHistory from './useFetchAccountHistory'
import { User, UserShopping } from '../types'

const DATA_COLUMNS = [
  { dataKey: 'id', header: 'ID', fixed: true, width: 80 },
  { dataKey: 'uuid', header: 'UUID', width: 330 },
  { dataKey: 'status_id', header: 'Status', width: 120 },
  { dataKey: 'brand_name', header: 'Brand Name', width: 180  },
  { dataKey: 'source_id', header: 'Shopping Trip ID (Source ID)', width: 330 },
  { dataKey: 'source_type_id', header: 'Source', width: 330 },
  { dataKey: 'amount', header: 'Amount', width: 120 },
  { dataKey: 'source_sub_id', header: 'Source Sub ID', width: 120 },
  { dataKey: 'metadata', header: 'Metadata', width: 300 },
  { dataKey: 'created_ts', header: 'Created Date', width: 200 },
  { dataKey: 'expiration_ts', header: 'Expiration Date', width: 160 },
]

export default (user: User) => {
  const accountId = user.id

  const [fetchTimestamp, setFetchTimestamp] = useState(Date.now())

  const {
    data, filter, length, loading, page, query, sortKey, wordWrap,
    setFilter, setLength, setPage, setQuery, setSort, setWordWrap,
  } = useFetchAccountHistory(
    fetchTimestamp,
    accountId,
    '/dashboard/pending-bolts',
    'id,uuid,status_id,brand_name,source_id,source_type_id,amount,source_sub_id,metadata,created_ts,expiration_ts',
    'Shopping',
  )

  const parsedData = parseData(data.data, query, (item: UserShopping) => ({
    id: item.id,
    uuid: item.uuid,
    status_id: parseCamelCase(transaction.stormshopStatusId[item.status_id]),
    brand_name: item.brand_name,
    source_type_id: parseCamelCase(transaction.txSourceTypeId[item.source_type_id]),
    source_id: item.source_id,
    amount: parseInt(item.amount),
    source_sub_id: item.source_sub_id || '-',
    metadata: item.metadata ? JSON.stringify(item.metadata) : '-',
    created_ts: item.created_ts ? formatDate(item.created_ts) : '-',
    expiration_ts: item.expiration_ts ? formatDate(item.expiration_ts) : '-',
    expirationTs: item.expiration_ts,
  }))

  return (
    <PanelGroup className="container-users-history-table container-users-history-shopping">
      <Panel>
        <InputGroup inside style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search for anything in current results"
            onChange={(value) => setQuery(value)}
          />
          <FilterPicker
            data={transaction.stormshopStatus}
            menuWidth={150}
            onChange={(status_id) => setFilter({ ...filter, status_id })}
            placeholder="Status"
          />
          <FilterPicker
            data={transaction.txSourceType}
            menuWidth={250}
            onChange={(source_type_id) => setFilter({ ...filter, source_type_id })}
            placeholder="Source"
            placement="autoVerticalEnd"
          />
          <IconButton
            active={wordWrap}
            disabled={loading}
            icon={<Icon icon="text-height" />}
            onClick={() => setWordWrap(!wordWrap)}
            style={{ marginLeft: 10 }}
          />
          <IconButton
            disabled={loading}
            icon={<Icon icon="refresh" />}
            onClick={() => setFetchTimestamp(Date.now())}
            style={{ marginLeft: 10 }}
          />
        </InputGroup>

        <DataTable
          accountId={accountId}
          actionColumnItems={ShoppingActions}
          columns={DATA_COLUMNS}
          data={parsedData}
          loading={loading}
          setFetchTimestamp={setFetchTimestamp}
          setSort={setSort}
          sortColumn={sortKey}
          user={user}
          wordWrap={wordWrap}
        />

        <Table.Pagination
          activePage={page}
          className="container-users-history-pagination"
          displayLength={length}
          total={data.meta.totalCount}
          onChangePage={(value) => setPage(value)}
          onChangeLength={(value) => setLength(value)}
          renderTotal={(total: number) => `Total: ${total || 0}, Visible: ${data.data.length}`}
        />
      </Panel>
    </PanelGroup>
  )
}
