import { transaction } from '@stormx/const'
import React, { useState } from 'react'
import { Icon, IconButton, Input, InputGroup, Panel, PanelGroup, Table } from 'rsuite'

import FilterPicker from 'components/FilterPicker'
import DataTable from 'components/users/History/DataTable'
import { formatDate, parseCamelCase, parseData } from 'util/utils'

import useFetchAccountHistory from './useFetchAccountHistory'
import { User, UserPayment } from '../types'

const DATA_COLUMNS = [
  { dataKey: 'id', header: 'ID', fixed: true, width: 100 },
  { dataKey: 'type_id', header: 'Type', width: 120 },
  { dataKey: 'uuid', header: 'UUID', width: 320 },
  { dataKey: 'status_id', header: 'Status', width: 140 },
  { dataKey: 'wallet_type_id', header: 'Wallet Type', width: 120 },
  { dataKey: 'wallet', header: 'Wallet', width: 200 },
  { dataKey: 'amount', header: 'Amount', width: 120 },
  { dataKey: 'currency_id', header: 'Currency' },
  { dataKey: 'created_at', header: 'Created Date', width: 200 },
  { dataKey: 'updated_at', header: 'Updated Date', width: 200 },
  { dataKey: 'transaction_id', header: 'Transaction ID', width: 270 },
  { dataKey: 'ip', header: 'IP', width: 150 },
  { dataKey: 'country', header: 'Country' },
  { dataKey: 'note', header: 'Note', width: 300 },
]

export default (user: User) => {
  const accountId = user.id
  
  const [fetchTimestamp, setFetchTimestamp] = useState(Date.now())

  const {
    data, filter, length, loading, page, query, sortKey, wordWrap,
    setFilter, setLength, setPage, setQuery, setSort, setWordWrap,
  } = useFetchAccountHistory(
    fetchTimestamp,
    accountId,
    '/dashboard/payments',
    'id,type_id,uuid,status_id,wallet_type_id,wallet,amount,currency_id,created_ts,updated_ts,tx_id,ip,country,note',
    'Payments',
  )

  const parsedData = parseData(data.data, query, (item: UserPayment) => ({
    id: item.id,
    type_id: parseCamelCase(transaction.txTypeId[item.type_id]),
    uuid: item.uuid,
    status_id: parseCamelCase(transaction.paymentStatusId[item.status_id]),
    wallet_type_id: parseCamelCase(transaction.walletTypeId[item.wallet_type_id]),
    wallet: item.wallet,
    amount: parseFloat(item.amount),
    currency_id: parseCamelCase(transaction.currencyId[item.currency_id]),
    created_at: formatDate(item.created_ts),
    updated_at: formatDate(item.updated_ts),
    transaction_id: item.tx_id,
    ip: item.ip,
    country: item.country,
    note: item.note || '-',
  }))

  return (
    <PanelGroup className="container-users-history-table container-users-history-payments">
      <Panel>
        <InputGroup inside style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search for anything in current results"
            onChange={(value) => setQuery(value)}
          />
          <FilterPicker
            data={transaction.txType}
            menuWidth={130}
            onChange={(type_id) => setFilter({ ...filter, type_id })}
            placeholder="Type"
          />
          <FilterPicker
            data={transaction.currency}
            menuWidth={160}
            onChange={(currency_id) => setFilter({ ...filter, currency_id })}
            placeholder="Currency"
          />
          <IconButton
            active={wordWrap}
            disabled={loading}
            icon={<Icon icon="text-height" />}
            onClick={() => setWordWrap(!wordWrap)}
            style={{ marginLeft: 10 }}
          />
          <IconButton
            disabled={loading}
            icon={<Icon icon="refresh" />}
            onClick={() => setFetchTimestamp(Date.now())}
            style={{ marginLeft: 10 }}
          />
        </InputGroup>

        <DataTable
          accountId={accountId}
          columns={DATA_COLUMNS}
          data={parsedData}
          loading={loading}
          setFetchTimestamp={setFetchTimestamp}
          setSort={setSort}
          sortColumn={sortKey}
          user={user}
          wordWrap={wordWrap}
        />

        <Table.Pagination
          activePage={page}
          className="container-users-history-pagination"
          displayLength={length}
          total={data.meta.totalCount}
          onChangePage={(value) => setPage(value)}
          onChangeLength={(value) => setLength(value)}
          renderTotal={(total: number) => `Total: ${total || 0}, Visible: ${data.data.length}`}
        />
      </Panel>
    </PanelGroup>
  )
}
