import React, { useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
import { Alert, Button, Icon, IconButton, Modal } from 'rsuite'
import { isEmpty, get } from 'lodash'
import omit from 'lodash/omit'

import DataHeader from 'components/DataHeader'
import DataRow from 'components/DataRow'
import api from 'util/api'
import { auth } from 'util/firebase'
import { remoteConfigState } from 'util/state'
import { formatDate, getRewardsLevelName, numberWithCommas } from 'util/utils'

import { UserWithUpdate } from '../types'
import { getRewardsImage } from '../../rewardLevel'

const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_URL as string

export default (userWithUpdate: UserWithUpdate) => {
  const { id, stormxRewards } = userWithUpdate

  const { STORMX_REWARDS } = useRecoilValue(remoteConfigState)

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const balanceATH = numberWithCommas(parseInt(get(stormxRewards, 'balanceATH', 0).toString()))
  const balanceSTMX = numberWithCommas(parseInt(get(stormxRewards, 'balanceSTMXV1', 0).toString()))
  const balanceSTMXV2 = numberWithCommas(parseInt(get(stormxRewards, 'balance', 0).toString()))

  const unlinkMembersWallet = async () => {
    setLoading(true)

    try {
      await api.delete(`/dashboard/accounts/${id}/unlinkMembersWallet`, {
        data: {
          note: `by ${auth().currentUser?.email} (id ${auth().currentUser?.uid})`,
        }
      })

      const newUserInfo = omit(userWithUpdate, ['setUsersInfo', 'stormxRewards'])
      // @ts-ignore
      userWithUpdate.setUsersInfo([newUserInfo])

      setShowModal(false)
      Alert.success('User Member Wallet unlinked', 4000)
    } catch (ex) {
      Alert.error('An error occured when Unlinking Member Wallet, please try again later', 4000)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Modal
        size="xs"
        show={showModal}
      >
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: '#ffb300',
              fontSize: 24,
              marginRight: 10,
            }}
          />
          This will remove the StormX Rewards wallet from the user’s account. The user will lose any staking rewards they accrued this week.
          Are you sure you want to continue?<br /><br />
          Is this account staking? <strong>{stormxRewards?.isStaking ? 'Yes' : 'No'}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            loading={loading}
            onClick={() => unlinkMembersWallet()}
          >Unlink</Button>
          <Button
            appearance="default"
            onClick={() => setShowModal(false)}
          >Cancel</Button>
        </Modal.Footer>
      </Modal>

      <DataHeader 
        component={<Fragment key="stormx-rewards">
          Rewards Program
          {!isEmpty(stormxRewards) && (<img
            alt={getRewardsLevelName(STORMX_REWARDS, stormxRewards.level)}
            src={getRewardsImage(stormxRewards.level)}
            width={24}
            style={{ marginLeft: 5 }}
          />)}
          {!isEmpty(stormxRewards) && (<IconButton
            appearance="subtle"
            disabled={loading}
            icon={<Icon icon="unlink" />}
            loading={loading}
            onClick={() => setShowModal(true)}
            style={{ marginLeft: 5 }}
          />)}
        </Fragment>}
        icon="gift"
      />
      {!isEmpty(stormxRewards) ? (<div>
        <DataRow content="Level" data={getRewardsLevelName(STORMX_REWARDS, stormxRewards.level)} />
        <DataRow
          content="Wallet address"
          data={<span>
            {stormxRewards.wallet}
            <Icon icon="external-link" style={{ marginLeft: 5 }} />
          </span>}
          href={`${ETHERSCAN_URL}?a=${stormxRewards.wallet}`}
        />
        <DataRow content="Joined at" data={formatDate(stormxRewards.createdAt)} />
        <DataRow content="Balance STMX v1" data={`${balanceSTMX} STMX`} />
        <DataRow content="Balance STMX v2" data={`${balanceSTMXV2} STMX`} />
        <DataRow content="Balance ATH" data={`${balanceATH} ATH`} />
        <DataRow content="Is staking STMX" data={stormxRewards?.isStakingSTMX ? 'Yes' : 'No'} />
        <DataRow content="Is staking ATH" data={stormxRewards?.isStakingATH ? 'Yes' : 'No'} />
      </div>) : "This user is not a member of Rewards Program"}
    </Fragment>
  )
}
