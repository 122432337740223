import { atom } from 'recoil'

export interface StormXRewardsLevel {
  cryptoBackBonus: number
  cryptoBackTimeReduction: number
  earnMultiple: number
  hours: number
  level: number
  name: string
  requiredBalance: number
}

export interface Rates {
  stormx?: number
}

export interface RemoteConfig {
  USD_IN_BOLTS: number
  STORMX_REWARDS: StormXRewardsLevel[]
}

const DEFAULT_RATES: Rates = {}

const DEFAULT_REMOTE_CONFIG: RemoteConfig = {
  USD_IN_BOLTS: 250000,
  STORMX_REWARDS: [],
}

export const ratesState = atom({
  key: 'rates',
  default: DEFAULT_RATES,
})


export const remoteConfigState = atom({
  key: 'remoteConfig',
  default: DEFAULT_REMOTE_CONFIG,
})
