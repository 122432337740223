import React from 'react'
import { Icon } from 'rsuite'
import { IconNames } from 'rsuite/lib/Icon/Icon'

interface DataHeaderProps {
  component: any,
  bigger?: boolean,
  icon?: IconNames,
}

export default ({ bigger, component, icon }: DataHeaderProps) => {
  const children = []
  if (icon) {
    children.push(<Icon
      icon={icon}
      key={icon}
      style={{
        fontSize: 24,
        marginRight: 8,
      }}
    />)
  }
  children.push(component)

  if (bigger) {
    return (<h3>{children}</h3>)
  } else {
    return (<h4>{children}</h4>)
  }
}
