import RewardsPurple from 'images/rewards-purple.png'
import RewardsBronze from 'images/rewards-bronze.png'
import RewardsSilver from 'images/rewards-silver.png'
import RewardsGold from 'images/rewards-gold.png'
import RewardsPlatinum from 'images/rewards-platinum.png'
import RewardsDiamond from 'images/rewards-diamond.png'

export const getRewardsImage = (accountLevel: number): string => {
  switch (accountLevel) {
    case 0: return RewardsPurple
    case 1: return RewardsBronze
    case 2: return RewardsSilver
    case 3: return RewardsGold
    case 4: return RewardsPlatinum
    case 5: return RewardsDiamond
    default: return ''
  }
}
