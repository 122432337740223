import React, { useState } from 'react'
import { Table } from 'rsuite'

import { User } from '../types'

type SortType = 'asc' | 'desc'

interface TableCustomResize {
  [key: string]: number | undefined
}

interface SortData {
  sortKey: string
  sortType: SortType
}

interface DataTableColumn {
  dataKey: string
  header: string
  notSortable?: boolean
  fixed?: boolean
  flexGrow?: number | undefined
  width?: number | undefined
}

interface HistoryShoppingProps {
  accountId: string
  columns: DataTableColumn[]
  data: any[]
  loading: boolean
  setFetchTimestamp: React.Dispatch<React.SetStateAction<number>>
  setSort: ({ sortKey, sortType }: SortData) => void
  sortColumn: string
  user: User
  wordWrap: boolean
  actionColumnItems?: React.ComponentType<any>
}

const DEFAULT_CUSTOM_RESIZE: TableCustomResize = {}

export default ({
  accountId,
  actionColumnItems: ActionColumnItems,
  columns,
  data,
  loading,
  setFetchTimestamp,
  setSort,
  sortColumn,
  user,
  wordWrap,
}: HistoryShoppingProps) => {
  const [customResize, setCustomResize] = useState(DEFAULT_CUSTOM_RESIZE)

  return (
    <Table
      data={data}
      key={`${wordWrap}`}
      height={500}
      loading={loading}
      onSortColumn={(sortKey, sortType) => setSort({ sortKey, sortType })}
      sortColumn={sortColumn}
      wordWrap={wordWrap}
    >
      {columns.map(({ dataKey, fixed, flexGrow, header, notSortable, width }: DataTableColumn) => (
        <Table.Column
          fixed={fixed ? 'left' : undefined}
          flexGrow={flexGrow}
          key={dataKey}
          onResize={(columnWidth) => setCustomResize({ ...customResize, [dataKey]: columnWidth })}
          sortable={!notSortable}
          resizable
          width={customResize[dataKey] || width}
        >
          <Table.HeaderCell>{header}</Table.HeaderCell>
          <Table.Cell dataKey={dataKey} />
        </Table.Column>
      ))}
      {ActionColumnItems && (
        <Table.Column
          fixed="right"
          key="actions-items"
          width={70}
        >
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {(rowData: any) => (<ActionColumnItems
              {...rowData}
              accountId={accountId}
              loading={loading}
              setFetchTimestamp={setFetchTimestamp}
              user={user}
            />)}
          </Table.Cell>
        </Table.Column>
      )}
    </Table>
  )
}