import React, { useEffect, useState } from 'react'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import { useSetRecoilState, SetterOrUpdater } from 'recoil'
import { Alert, Loader } from 'rsuite'

import DashboardRoute from 'components/DashboardRoute'
import PublicRoute from 'components/PublicRoute'

import Login, { LOGIN_ROUTE } from 'components/login'
import containerItems, { DEFAULT_ROUTE } from 'containers'
import NotFound from 'containers/NotFound'
import api from 'util/api'
import { getValue, LS_DARK_MODE, LS_FIREBASE_EMAIL_KEY, removeValue } from 'util/storage'
import { auth } from 'util/firebase'
import { ratesState, remoteConfigState, Rates, RemoteConfig } from 'util/state'

if (true || getValue(LS_DARK_MODE) === 'true') {
  require('rsuite/lib/styles/themes/dark/index.less')
} /*else {
  require('rsuite/lib/styles/themes/default/index.less')
}*/
require('./styles.less')

const waitForAuthChanged = (
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setRates: SetterOrUpdater<Rates>,
  setRemoteConfig: SetterOrUpdater<RemoteConfig>,
  history: any,
) =>
  auth().onAuthStateChanged(async (user) => {
    try {
      if (!user) {
        throw new Error('User not authenticated')
      }
      const [configResponse, ratesResponse] = await Promise.all([
        api.get('/config'),
        api.get('/rates'),
      ])
      setRemoteConfig(configResponse.data)
      setRates(ratesResponse.data)
      setAuthenticated(!!user)
      setLoading(false)
    } catch (ex) {
      auth().signOut().then(() => {
        history.push(LOGIN_ROUTE)
        setLoading(false)
      })
    }
  })

export default () => {
  const history = useHistory()
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const setRates = useSetRecoilState(ratesState)
  const setRemoteConfig = useSetRecoilState(remoteConfigState)

  useEffect(() => {
    if (auth().isSignInWithEmailLink(window.location.href)) {
      const email = getValue(LS_FIREBASE_EMAIL_KEY)
  
      if (email) {
        auth().signInWithEmailLink(email as string, window.location.href)
          .then(async () => {
            waitForAuthChanged(setAuthenticated, setLoading, setRates, setRemoteConfig, history)

            const response = await api.post('/authorize')
            await auth().signInWithCustomToken(response.data.token)

            removeValue(LS_FIREBASE_EMAIL_KEY)

            setAuthenticated(true)
            setLoading(false)

            history.push(DEFAULT_ROUTE)

            Alert.success('You have successfully signed in')
          }).catch(() => setAuthenticated(false))
      } else {
        history.push(LOGIN_ROUTE)
      }
    } else {
      waitForAuthChanged(setAuthenticated, setLoading, setRates, setRemoteConfig, history)
    }
  }, [history, setRates, setRemoteConfig])

  if (loading) {
    return (<Loader
      backdrop
      content="loading..."
      size="lg"
      vertical
    />)
  } else {
    return (<Switch>
      <Route
        exact
        path="/"
      >
        <Redirect to={LOGIN_ROUTE} />
      </Route>

      {containerItems.map((containerItem) => (
        <DashboardRoute
          authenticated={authenticated}
          component={containerItem.component}
          exact
          key={containerItem.key}
          path={containerItem.path}
        />
      ))}

      <PublicRoute
        authenticated={authenticated}
        component={Login}
        exact
        path={LOGIN_ROUTE}
      />

      <Route component={NotFound} />
    </Switch>)
  }
}
