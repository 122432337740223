import React, { useState } from 'react'
import { Icon, IconButton, Input, InputGroup, Panel, PanelGroup, Table } from 'rsuite'

import DataTable from 'components/users/History/DataTable'
import { formatDate, parseData } from 'util/utils'

import useFetchAccountHistory from './useFetchAccountHistory'
import { User, UserChanges } from '../types'

const DATA_COLUMNS = [
  { dataKey: 'id', header: 'ID', fixed: true, width: 330 },
  { dataKey: 'action', header: 'Action', width: 120 },
  { dataKey: 'field', header: 'Field', width: 150 },
  { dataKey: 'data', header: 'Data', width: 400 },
  { dataKey: 'caller', header: 'Caller', width: 120 },
  { dataKey: 'ip', header: 'IP', width: 140 },
  { dataKey: 'country', header: 'Country', width: 100 },
  { dataKey: 'ua', header: 'UA', width: 350 },
  { dataKey: 'notes', header: 'Notes', width: 300 },
  { dataKey: 'createdAt', header: 'Timestamp', width: 180 },
]

export default (user: User) => {
  const accountId = user.id

  const [fetchTimestamp, setFetchTimestamp] = useState(Date.now())

  const {
    data, length, loading, page, query, sortKey, wordWrap,
    setLength, setPage, setQuery, setSort, setWordWrap,
  } = useFetchAccountHistory(
    fetchTimestamp,
    accountId,
    '/dashboard/logs',
    'id,action,field,data,caller,meta.ip,meta.country,meta.ua,notes,createdAt',
    'Changes',
    true,
    { sortKey: 'createdAt', sortType: 'desc' }
  )

  const parsedData = parseData(data.data, query, (item: UserChanges) => ({
    id: item.id,
    action: item.action,
    field: item.field,
    data: JSON.stringify(item.data),
    caller: item.caller,
    ip: (item.meta && item.meta.ip) || '-',
    country: (item.meta && item.meta.country) || '-',
    ua: (item.meta && item.meta.ua) || '-',
    notes: item.notes || '-',
    createdAt: formatDate(item.createdAt),
  }))

  return (
    <PanelGroup className="container-users-history-table container-users-history-changes">
      <Panel>
        <InputGroup inside style={{ marginBottom: 10 }}>
          <Input
            placeholder="Search for anything in current results"
            onChange={(value) => setQuery(value)}
          />
          <IconButton
            active={wordWrap}
            disabled={loading}
            icon={<Icon icon="text-height" />}
            onClick={() => setWordWrap(!wordWrap)}
            style={{ marginLeft: 10 }}
          />
          <IconButton
            disabled={loading}
            icon={<Icon icon="refresh" />}
            onClick={() => setFetchTimestamp(Date.now())}
            style={{ marginLeft: 10 }}
          />
        </InputGroup>

        <DataTable
          accountId={accountId}
          columns={DATA_COLUMNS}
          data={parsedData}
          loading={loading}
          setFetchTimestamp={setFetchTimestamp}
          setSort={setSort}
          sortColumn={sortKey}
          user={user}
          wordWrap={wordWrap}
        />

        <Table.Pagination
          activePage={page}
          className="container-users-history-pagination"
          displayLength={length}
          total={data.meta.totalCount}
          onChangePage={(value) => setPage(value)}
          onChangeLength={(value) => setLength(value)}
          renderTotal={(total: number) => `Total: ${total || 0}, Visible: ${data.data.length}`}
        />
      </Panel>
    </PanelGroup>
  )
}
