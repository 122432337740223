import React, { useEffect, useState } from 'react'
import { Icon, IconButton, Input, InputGroup, Tag } from 'rsuite'
import validator from 'validator'

import { clearLocationHash, parseParams, setHashParam } from 'util/utils'

import { SearchFetchQuery } from './types'

enum SearchType {
  NONE = '',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE NUMBER',
  REFERRAL = 'REFERRAL',
  ACCOUNT_ID = 'ACCOUNT ID',
  WALLET = 'WALLET',
}

interface SearchProps {
  fetchAccounts: (fetchQuery: SearchFetchQuery) => void,
  loading: boolean,
}

const REFERRAL_REGEXP = /^[A-Z0-9]{6-8}$/

const getFetchQuery = (query: string): [SearchFetchQuery, SearchType] => {
  const fetchQuery: SearchFetchQuery = {
    limit: 100,
    skip: 0,
  }
  let type = SearchType.NONE
  query = query.trim()

  if (validator.isEmail(query)) {
    type = SearchType.EMAIL
    fetchQuery.email = query
  } else if (validator.isUUID(query)) {
    type = SearchType.ACCOUNT_ID
    fetchQuery.id = query
  } else if (validator.isMobilePhone(query)) {
    type = SearchType.PHONE_NUMBER
    fetchQuery.phoneNumber = query.replace('+', '')
  } else if (REFERRAL_REGEXP.test(query)) { // eg: 06EKAQ2Z, length 6 or 8, numbers and all letter uppercased
    type = SearchType.REFERRAL
    fetchQuery.referral_code = query
  } else if (validator.isEthereumAddress(query)) {
    type = SearchType.WALLET
    fetchQuery.wallet_address = query
  } else if (query[0] === '@' && validator.isEmail(query.substr(1))) {
    type = SearchType.WALLET
    fetchQuery.wallet_address = query.substr(1)
  }

  return [fetchQuery, type]
}

export default ({ fetchAccounts, loading }: SearchProps) => {
  const [loaded, setLoaded] = useState(false)
  const [query, setQuery] = useState('')

  let input: HTMLElement = document.createElement('div')
  
  const [fetchQuery, type] = getFetchQuery(query)

  const setNewQuery = (value: string) => {
    setQuery(value)
    if (value) {
      setHashParam('q', value)
    } else {
      clearLocationHash()
    }
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      const query = parseParams(decodeURIComponent(window.location.hash))['q']
      if (query) {
        setQuery(query)
        const [fetchQuery] = getFetchQuery(query)
        fetchAccounts(fetchQuery)
      } else {
        input?.focus()
      }
    }
  }, [fetchAccounts, input, loaded])

  return (
    <div style={{ marginBottom: 20 }}>
      <InputGroup>
        {!loading && type && (<Tag style={{
          fontSize: 15,
          opacity: 0.7,
          position: 'absolute',
          right: 135,
          top: 6,
          zIndex: 10,
        }}>{type}</Tag>)}

        <Input
          disabled={loading}
          inputRef={ref => input = ref}
          onChange={(value) => setNewQuery(value)}
          onPressEnter={() => !loading && fetchAccounts(fetchQuery)}
          placeholder="Search for the account(s) using accountId, email, phone number, referral code or wallet address (use @ prefix)"
          size="lg"
          value={query}
        />
        <IconButton
          disabled={loading}
          icon={<Icon icon="search" />}
          loading={loading}
          onClick={() => !loading && fetchAccounts(fetchQuery)}
          placement="right"
          size="lg"
          style={{
            marginLeft: loading ? -40 : undefined,
            paddingRight: loading ? 120 : undefined,
            width: loading ? 0 : 135,
          }}
        >{loading ? '' : 'Search'}</IconButton>
      </InputGroup>
    </div>
  )
}
