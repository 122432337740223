import axios, { AxiosRequestConfig } from 'axios'

import { auth } from 'util/firebase'

const API_URL = process.env.REACT_APP_API_URL as string

const api = axios.create({
  baseURL: API_URL,
})

api.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (auth().currentUser) {
    const idToken = await auth().currentUser?.getIdToken()
    if (idToken) {
      config.headers.authorization = `Bearer ${idToken}`
    }
  }
  return config
})

export default api
