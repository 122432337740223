import React from 'react'
import { FlexboxGrid } from 'rsuite'

import isUndefined from 'lodash/isUndefined'

interface DataRowProps {
  content: string
  data: any
  href?: string
  onClick?: Function
  style?: React.CSSProperties
  subcontent?: string
}

export default ({ content, data, href, onClick, style, subcontent }: DataRowProps) => (
  <FlexboxGrid onClick={onClick || (() => {})} style={style}>
    <FlexboxGrid.Item colspan={subcontent ? 4 : 8}>
      {content}
    </FlexboxGrid.Item>
    {subcontent && (<FlexboxGrid.Item colspan={4}>
      {subcontent}
    </FlexboxGrid.Item>)}
    <FlexboxGrid.Item
      className={'data-row-value'}
      colspan={subcontent ? 16 : 16}
    >
      {href ?
        (<a
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {!isUndefined(data) ? <b>{data}</b> : '-'}
        </a>) :
        (!isUndefined(data) ? <b>{data}</b> : '-')}
    </FlexboxGrid.Item>
  </FlexboxGrid>
)
