import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon, IconButton, Nav, Sidenav } from 'rsuite'

import { LOGIN_ROUTE } from 'components/login'
import containerItems from 'containers'
import WordmarkBlack from 'images/wordmark-black.svg'
import WordmarkWhite from 'images/wordmark-white.svg'
import MarkBlack from 'images/stormx-mark-black.svg'
import MarkWhite from 'images/stormx-mark-white.svg'
import { auth } from 'util/firebase'
import { getValue, LS_DARK_MODE, setValue } from 'util/storage'

const isDarkMode = true || getValue(LS_DARK_MODE) === 'true'

interface SidebarProps {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export default ({ expanded, setExpanded }: SidebarProps) => {
  const history = useHistory()

  const currentKey = containerItems.find(navItem => navItem.path === history.location.pathname)
  const [activeKey, setActiveKey] = useState(currentKey?.key)

  const onLogout = () => {
    auth().signOut().then(() => {
      history.push(LOGIN_ROUTE)
    })
  }

  const setDarkMode = () => {
    setValue(LS_DARK_MODE, `${!isDarkMode}`)
    window.location.reload()
  }

  return (<div
    className="main-sidebar rs-sidenav-default"
    style={{ width: expanded ? 250 : 55 }}
  >
    <Sidenav
      activeKey={activeKey}
      expanded={expanded}
    >
      <img
        alt="StormX Dashboard"
        className="sidebar-logo"
        src={isDarkMode ? WordmarkWhite : WordmarkBlack}
        style={{ marginLeft: expanded ? 15 : -250 }}
      />
      <img
        alt="StormX Dashboard"
        className="sidebar-logo-small"
        src={isDarkMode ? MarkWhite : MarkBlack}
        style={{ left: expanded ? -200 : undefined }}
      />
      <IconButton
        className="sidebar-button"
        circle={true}
        icon={<Icon icon={expanded ? 'angle-left' : 'angle-right'} />}
        onClick={() => setExpanded(!expanded)}
      />
      <Sidenav.Body>
        <Nav className="main-navigation">
          {containerItems.map((containerItem) => (<Nav.Item
            key={containerItem.key}
            eventKey={containerItem.key}
            icon={<Icon icon={containerItem.icon} />}
            onClick={() => {
              setActiveKey(containerItem.key)
              history.push(containerItem.path)
            }}
          >
            {containerItem.text}
          </Nav.Item>))}
          <Nav.Item
            className={`main-navigation-dark-mode${!expanded ? ' main-navigation-dark-mode-collapsed' : ''}`}
            icon={<Icon icon={isDarkMode ? 'lightbulb-o' : 'moon-o'} />}
            onClick={() => setDarkMode()}
            style={{ display: 'none' }}
          >
            Set {isDarkMode ? 'Bright' : 'Dark'} Mode
          </Nav.Item>
          <Nav.Item
            className={`main-navigation-logout${!expanded ? ' main-navigation-logout-collapsed' : ''}`}
            icon={<Icon icon="sign-out" />}
            onClick={onLogout}
          >
            {expanded ? '' : 'Logout '}{auth().currentUser?.email}
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  </div>)
}
