import React, { Fragment } from 'react'
import { Col, Panel, PanelGroup, Row } from 'rsuite'

import { User, UserPreferences, UserWallets } from '../types'
import InfoReferral from './Referral'
import SignUpData from './SignUpData'
import InfoSocial from './Social'
import DataHeader from 'components/DataHeader'
import DataRow from 'components/DataRow'

interface BasicInfoProps {
  loading: boolean
  setUsersInfo: (item: User[]) => void,
  user: User
}

const Preferences = (preferences: UserPreferences) => (
  <div style={{ marginTop: 20 }}>
    <DataHeader component="Preferences" icon="sliders" />
    {Object.keys(preferences).length === 0 ? 'No preferences set for this user' : ''}
    {Object.keys(preferences).map((key) => (
      <DataRow
        content={key}
        data={preferences[key].toString()}
        key={key}
      />
    ))}
  </div>
)

const WalletsDetails = (wallets: UserWallets) => (
  <Fragment>
    <DataHeader component="Wallets" icon="money" />
    {Object.keys(wallets).length === 0 ? 'No wallets set for this user' : ''}
    {Object.keys(wallets).sort().map((key) => (
      <DataRow
        content={key}
        data={wallets[key].address}
        key={key}
        subcontent={wallets[key].type}
      />
    ))}
  </Fragment>
)

export default ({ loading, setUsersInfo, user }: BasicInfoProps) => (
  <PanelGroup
    accordion
    bordered
    className="container-users-detailedInfo"
    onSelect={() => {}}
  >
    <Panel
      header="Detailed Info"
      defaultExpanded
    >
      {!loading && user && (<Row>
        <Col key="left" md={12}>
          <WalletsDetails {...user.wallets} />
          <SignUpData accountId={user.id} />
          <Preferences {...user.preferences} />
          <InfoSocial
            {...user}
            setUsersInfo={setUsersInfo}
          />
        </Col>
        <Col key="right" md={12}>
          <InfoReferral
            accountId={user.id}
            referral={user.referral}
          />
        </Col>
      </Row>)}
    </Panel>
  </PanelGroup>
)
