import { account } from '@stormx/const'
import React, { useEffect, useState, Fragment } from 'react'
import { Alert, Button, Icon, Input, List, Loader, Modal, Popover, SelectPicker, Tag, Whisper } from 'rsuite'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import omit from 'lodash/omit'

import { UserStatus, UserWithUpdate } from '../types'
import api from 'util/api'
import { auth } from 'util/firebase'
import { formatDate } from 'util/utils'

interface AccountStatusItem {
  caller: string
  data: {
    status: string
  } | string
  notes: string
  updatedAt: string
}

const OTHER_REASON = 'Other' // this is used for custom reason input field

const UPDATE_REASONS = [
  'Continuous use of VPN',
  'One offer then withdrew',
  'Wallet sharing',
  'Unrealistic click to conversion time',
  OTHER_REASON,
]

const getStatusText = (status: string, isLegacy?: boolean): string => {
  if (!isBoolean(isLegacy)) {
    isLegacy = status[0] === status[0].toUpperCase()
  }

  if (isLegacy) {
    switch (status) {
      case 'Active': return 'Active'
      case 'Banned': return 'Banned'
      case 'Inactive': return 'Inactive'
      case 'OffersLimited': return 'Offers Limited'
      case 'WithdrawBanned': return 'Withdraw Banned'
      default: return 'unknown' // this should never appear
    }
  } else {
    const { active, banned, deactivated, offersLimited, withdrawBanned } = account.status
    switch (status) {
      case active: return 'Active'
      case banned: return 'Banned'
      case deactivated: return 'Deactivated'
      case offersLimited: return 'Offers Limited'
      case withdrawBanned: return 'Withdraw Banned'
      default: return 'unknown' // this should never appear
    }
  }
}

const renderStatus = (status: string, isLegacy: boolean) => {
  const { active, banned, deactivated, offersLimited, withdrawBanned } = account.status
  const statusText = getStatusText(status, isLegacy)
  const style: React.CSSProperties = {
    fontWeight: 'bold',
    padding: '0 8px',
  }

  if (isLegacy) {
    switch (status) {
      case 'Active': return (<Tag color="green" style={style}>{statusText}</Tag>)
      case 'Banned': return (<Tag color="violet" style={style}>{statusText}</Tag>)
      case 'Inactive': return (<Tag color="yellow" style={style}>{statusText}</Tag>)
      case 'OffersLimited': return (<Tag color="cyan" style={style}>{statusText}</Tag>)
      case 'WithdrawBanned': return (<Tag color="blue" style={style}>{statusText}</Tag>)
      default: return (<Tag style={style}>unknown</Tag>) // this should never appear
    }
  } else {
    switch (status) {
      case active: return (<Tag color="green" style={style}>{statusText}</Tag>)
      case banned: return (<Tag color="violet" style={style}>{statusText}</Tag>)
      case deactivated: return (<Tag color="yellow" style={style}>{statusText}</Tag>)
      case offersLimited: return (<Tag color="cyan" style={style}>{statusText}</Tag>)
      case withdrawBanned: return (<Tag color="blue" style={style}>{statusText}</Tag>)
      default: return (<Tag style={style}>unknown</Tag>) // this should never appear
    }
  }
}

const StatusHistory = ({ accountId }: { accountId: string }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getBalance = async () => {
      setLoading(true)
  
      try {
        const fields = 'caller,data,notes,updatedAt'
        const filter = `accountId:eq:${accountId},field:eq:status`
        const sort = `sort=updatedAt:desc`
        const { data: { data } } = await api.get(`/dashboard/logs?fields=${fields}&filter=${filter}&sort=${sort}`)
        setData(data)
      } catch (ex) {
        Alert.error('An error occured when fetching Status history, please try again later', 4000)
      } finally {
        setLoading(false)
      }
    }

    getBalance()
  }, [accountId])

  return (
    <Whisper
      trigger="click"
      speaker={<Popover title="Account Status history">{!data.length || loading ? (<Loader />) : (<List>
          {data.map((item: AccountStatusItem) =>
            <List.Item style={{ padding: '5px 10px 5px 10px' }}>
              Status: <b>{getStatusText(isString(item.data) ? item.data : item.data.status)}</b><br />
              Notes: <b>{item.notes}</b><br />
              Caller: <b>{item.caller}</b><br />
              Date: <b>{formatDate(item.updatedAt)}</b>
            </List.Item>
          )}
        </List>)}</Popover>}
    >
      <span className="element-interaction">
        <Icon icon="detail" style={{ marginLeft: 5 }} />
      </span>
    </Whisper>
  )
}

export default (userWithUpdate: UserWithUpdate) => {
  const [reason, setReason] = useState('')
  const [reasonText, setReasonText] = useState('')
  const [status, setStatus] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  // TODO: at the time of implementing this dashboard we didn't do account database schema
  //       changes required for Firebase update thus legacy status is also supported.
  //       Legacy format should be removed after migrating the accounts to the new schema.
  const isLegacy = userWithUpdate.status[0].toUpperCase() === userWithUpdate.status[0]
  const statusPickerData = isLegacy ?
    ['Active', 'Banned', 'Inactive', 'OffersLimited', 'WithdrawBanned']
      .map((value) => ({
        label: getStatusText(value, isLegacy),
        value,
      })) :
    Object.keys(account.status)
      .filter((key) => key !== userWithUpdate.status.toLowerCase())
      .map((value) => ({
        label: getStatusText(value, isLegacy),
        value,
      })
    )

  const clearFields = () => {
    setReason('')
    setReasonText('')
    setStatus('')
  }

  const updateAccountStatus = async () => {
    setUpdateLoading(true)

    try {
      if (status !== userWithUpdate.status) {
        await api.patch(`/dashboard/accounts/${userWithUpdate.id}/status/${status}`, {
          reason: `${reason === OTHER_REASON ? reasonText : reason} - ` +
            `by ${auth().currentUser?.email} (id ${auth().currentUser?.uid})`
        })

        const newUserInfo = omit(userWithUpdate, 'setUsersInfo')
        newUserInfo.status = status as UserStatus
        userWithUpdate.setUsersInfo([newUserInfo])

        setShowModal(false)
        Alert.success('User Account Status updated successfully', 4000)
      }
    } catch (ex) {
      Alert.error('An error occured when updating Account Status, please try again later', 4000)
    } finally {
      setUpdateLoading(false)
    }
  }

  return (
    <Fragment>
      {renderStatus(userWithUpdate.status, isLegacy)}
      <span
        className="element-interaction"
        onClick={() => setShowModal(!showModal)}
      >
        <Icon icon="edit2" style={{ marginLeft: 5 }} />
      </span>
      <StatusHistory accountId={userWithUpdate.id} />
      <Modal
        onExited={() => clearFields()}
        show={showModal}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Change Account Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectPicker
            block
            cleanable={false}
            data={statusPickerData}
            disabled={updateLoading}
            placeholder="Select New User Status"
            renderMenuItem={(_label, item) => renderStatus(item.value, isLegacy)}
            renderValue={(value) => renderStatus(value, isLegacy)}
            onChange={(status: UserStatus) => setStatus(status)}
            searchable={false}
          />
          {status && (<SelectPicker
            block
            cleanable={false}
            data={UPDATE_REASONS.map((value) => ({
              label: value,
              value,
            }))}
            disabled={updateLoading}
            placeholder="Select Update Reason"
            onChange={(reason: string) => setReason(reason)}
            searchable={false}
            style={{ marginTop: 15 }}
          />)}
          {reason === OTHER_REASON && (<Input
            componentClass="textarea"
            disabled={updateLoading}
            onChange={(value) => setReasonText(value)}
            placeholder="Enter the reason"
            style={{
              marginTop: 15,
              resize: 'vertical',
            }}
          />)}
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            disabled={!status || !reason || (reason === OTHER_REASON && !reasonText)}
            loading={updateLoading}
            onClick={() => updateAccountStatus()}
          >Update</Button>
          <Button
            appearance="default"
            onClick={() => setShowModal(false)}
          >Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
