import React from 'react'
import { Table } from 'rsuite'
import isString from 'lodash/isString'
import trim from 'lodash/trim'

import { formatDate } from 'util/utils'

import { User } from './types'

interface UsersProps {
  loading: boolean
  setUsersInfo: (item: User[]) => void,
  users: User[]
}

export default ({ loading, setUsersInfo, users }: UsersProps) => {
  const data = users.map((item) => ({
    id: item.id,
    name: item.name ? trim(`${trim(item.name.first)} ${trim(item.name.last)}`) : '-',
    email: isString(item.email) ? item.email : item.email.display,
    phoneNumber: item.phone.phoneNumber,
    status: item.status,
    createdAt: formatDate(item._insertTs),
  }))

  const onRowClick = (item: User) => {
    const user = users.find((user) => item.id === user.id) as User
    setUsersInfo([user])
  }

  return (
    <Table
      autoHeight
      className="table-row-action"
      data={data}
      loading={loading}
      onRowClick={onRowClick}
    >
      <Table.Column resizable width={330}>
        <Table.HeaderCell>Account ID</Table.HeaderCell>
        <Table.Cell dataKey="id" />
      </Table.Column>
      <Table.Column resizable width={200}>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.Cell dataKey="name" />
      </Table.Column>
      <Table.Column resizable width={300}>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.Cell dataKey="email" />
      </Table.Column>
      <Table.Column resizable width={200}>
        <Table.HeaderCell>Phone Number</Table.HeaderCell>
        <Table.Cell dataKey="phoneNumber" />
      </Table.Column>
      <Table.Column resizable width={140}>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.Cell dataKey="status" />
      </Table.Column>
      <Table.Column resizable width={200}>
        <Table.HeaderCell>Created At</Table.HeaderCell>
        <Table.Cell dataKey="createdAt" />
      </Table.Column>
    </Table>
  )
}
