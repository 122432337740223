import React, { useEffect, useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
import { Alert, Loader } from 'rsuite'
import isEmpty from 'lodash/isEmpty'

import DataHeader from 'components/DataHeader'
import DataRow from 'components/DataRow'
import api from 'util/api'
import { remoteConfigState } from 'util/state'
import { formatDate, getRewardsLevelName } from 'util/utils'

import { UserReferral } from '../types'

interface ReferralProps {
  accountId: string
  referral: UserReferral
}

interface ReferralSummary {
  count: number,
  status: string
}

interface ReferralV2Stats {
  consumer?: {
    amount: string
    status: number
    lockingDate: string
    level: number
  }
  provider?: {
    completedAmount: string
    completedCount: number
    pendingAmount: string
    pendingCount: number
    sentCount: number
  }
}

const ReferralSummary = ({ summary }: { summary: ReferralSummary[] }) => (
  <div style={{ marginTop: 10 }}>
    {!isEmpty(summary) && (<h6>Status Summary</h6>)}
    {summary?.map((userCount, i) =>
      (<DataRow content={userCount.status} data={userCount.count} key={i} />)
    )}
  </div>
)

const ReferralV2Stats = ({ referral, stats }: { referral: UserReferral, stats: ReferralV2Stats }) => {
  const { STORMX_REWARDS } = useRecoilValue(remoteConfigState)

  return (
    <div style={{ marginTop: 20 }}>
      <DataHeader component="Referral v2" icon="peoples" />
      {stats.consumer && (<Fragment>
        <h6>Consumer</h6>
        <DataRow
          content="Amount Earned"
          data={stats.consumer?.amount ? `$${parseInt(stats.consumer.amount)}` : undefined}
        />
        <DataRow
          content="Level"
          data={getRewardsLevelName(STORMX_REWARDS, stats.consumer.level)}
        />
        <DataRow
          content="Locking Date"
          data={formatDate(stats.consumer?.lockingDate)}
        />
        <DataRow
          content="Status"
          data={stats.consumer?.status}
        />
      </Fragment>)}

      {stats.provider && (<Fragment>
        <h6 style={{ marginTop: 5 }}>Provider</h6>
        <DataRow
          content="Completed Amount Earned"
          data={stats.provider?.completedAmount ? `$${stats.provider.completedAmount}` : undefined}
        />
        <DataRow
          content="Completed Count"
          data={stats.provider?.completedCount}
        />
        <DataRow
          content="Pending Amount Earned"
          data={stats.provider?.pendingAmount ? `$${stats.provider.pendingAmount}` : undefined}
        />
        <DataRow
          content="Pending Count"
          data={stats.provider?.pendingCount}
        />
        <DataRow
          content="Sent Count"
          data={stats.provider?.sentCount}
        />
      </Fragment>)}

      {referral?.v2?.provider.accountId && (
        <DataRow
          content="Provider Account ID"
          data={referral?.v2?.provider.accountId}
        />
      )}
      {referral?.v2?.provider.code && (
        <DataRow
          content="Provider Code"
          data={referral?.v2?.provider.code}
        />
      )}
      {referral?.v2?.provider.createdAt && (
        <DataRow
          content="Created At"
          data={formatDate(referral?.v2?.provider.createdAt)}
        />
      )}
    </div>
  )
}

export default ({ accountId, referral }: ReferralProps) => {
  const [referralSummary, setReferralSummary] = useState([])
  const [referralV2Stats, setReferralV2Stats] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingV2, setLoadingV2] = useState(false)

  useEffect(() => {
    const getReferralSummary = async () => {
      setLoading(true)
  
      try {
        const { data } = await api.get(`/dashboard/accounts/${accountId}/referral-summary`)
        setReferralSummary(data.payload)
      } catch (ex) {
        Alert.error('An error occured when fetching Account Referral summary, please try again later', 4000)
      } finally {
        setLoading(false)
      }
    }

    const getReferralStatsV2 = async () => {
      setLoadingV2(true)
  
      try {
        const { data } = await api.get(`/dashboard/accounts/${accountId}/referrals/stats`)
        setReferralV2Stats(data)
      } catch (ex) {
        Alert.error('An error occured when fetching Account Referral V2 stats, please try again later', 4000)
      } finally {
        setLoadingV2(false)
      }
    }

    getReferralSummary()
    getReferralStatsV2()
  }, [accountId])

  return (
    <Fragment>
      <DataHeader component="Referral" icon="people-group" />
      <DataRow content="Code" data={referral.code} />
      {referral?.consumer && (<Fragment>
        <DataRow content="Success count" data={referral.consumer.successCount} />
        <DataRow content="Used count" data={referral.consumer.usedCount} />
      </Fragment>)}
      {referral?.stormshop?.consumer && (<Fragment>
        <DataRow content="Shop success count" data={referral.stormshop.consumer.successCount} />
        <DataRow content="Shop used count" data={referral.stormshop.consumer.usedCount} />
      </Fragment>)}
      {referral?.provider && (<Fragment>
        <h6 style={{ marginTop: 8 }}>Provider</h6>
        <DataRow content="Account ID" data={referral.provider.accountId} />
        <DataRow content="Code" data={referral.provider.code} />
        <DataRow content="Created at" data={referral.provider.insertTs} />
        <DataRow content="Reward Status" data={referral.provider.rewardStatus} />
      </Fragment>)}
      {loading ?
        (<div><Loader style={{ marginTop: 10 }} /></div>) :
        (<ReferralSummary summary={referralSummary} />)}
      {loadingV2 ?
        (<div><Loader style={{ marginTop: 10 }} /></div>) :
        (<ReferralV2Stats
          referral={referral}
          stats={referralV2Stats}
        />)}
    </Fragment>
  )
}
